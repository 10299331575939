import * as API from '../services/urlAPI';

export const ApiConstants = {
  ADMIN_LOGIN_URL: API.baseUrl + '/b2b-user/managers/login',
  ADMIN_FORGOT_PASSWORD_URL: API.baseUrl + '/b2b-user/managers/forgot/password',
  ADMIN_CHANGE_PASSWORD_FORGOT_URL: API.baseUrl + '/b2b-user/managers/change/forgot/password',
  ADMIN_CHANGE_PASSWORD_URL: API.baseUrl + '/b2b-user/managers/change/password',
  ADMIN_UPDATE_PASSWORD_URL: API.baseUrl + '/b2b-user/managers/update/password',
  ADMIN_EDIT_PROFILE_URL: API.baseUrl + '/b2b-user/managers/edit',

  USER_SEARCH_URL: API.baseUrl + '/b2b-user/users/search?pageNumber=%s&pageSize=%s&email=%s&name=%s&roleIds=%s&agentCode=%s&sortBy=%s&sortDirection=%s',
  AGGENT_SEARCH_URL: API.baseUrl + '/b2b-user/users/searchV2?pageNumber=%s&pageSize=%s&email=%s&name=%s&roleIds=%s&agentCode=%s&sortBy=%s&sortDirection=%s',
  USER_BY_ID_URL: API.baseUrl + '/b2b-user/users/%s',
  USER_BY_EMAIL_URL: API.baseUrl + '/b2b-user/users/user?appKey=%s&email=%s',
  USER_BY_COMPANY_URL: API.baseUrl + '/b2b-user/companies/%s/users',
  USER_ROLE_URL: API.baseUrl + '/b2b-user/roles/get',
  USER_DELETE_URL: API.baseUrl + '/b2b-user/users/delete',
  USER_CREATE_URL: API.baseUrl + '/b2b-user/users/admin/register',
  USER_EDIT_URL: API.baseUrl + '/b2b-user/users/admin/edit',
  USER_RESET_PASSWORD_URL: API.baseUrl + '/b2b-user/users/sendResetPasswordEmails',

  // COMPANY_SEARCH_URL: API.baseUrl + '/b2b-user/companies/search?appKey=%s&pageNumber=%s&pageSize=%s&name=%s&owner=%s&phone=%s&address=%s&branchCode=%s&sortBy=%s&sortDirection=%s',
  COMPANY_SEARCH_URL: API.baseUrl + '/b2b-user/companies/searchV2?appKey=%s&pageNumber=%s&pageSize=%s&name=%s&owner=%s&phone=%s&address=%s&branchCode=%s&sortBy=%s&sortDirection=%s',
  COMPANY_DELETE_URL: API.baseUrl + '/b2b-user/companies/delete',
  COMPANY_CREATE_URL: API.baseUrl + '/b2b-user/companies/create',
  COMPANY_EDIT_URL: API.baseUrl + '/b2b-user/companies/edit',
  COMPANY_BY_USER_URL: API.baseUrl + '/b2b-user/users/%s/companies?pageNumber=%s&pageSize=%s',
  AGGENT_BY_USER_URL: API.baseUrl + '/b2b-user/users/getAgentToStaff/%s',

  COMPANY_BY_ID_URL: API.baseUrl + '/b2b-user/companies/%s?appKey=%s',

  SETTINGS_URL: API.baseUrl + '/b2b-user/settings?appKey=%s',
  SETTINGS_SAVE_URL: API.baseUrl + '/b2b-user/settings/save',

  ADDRESS_CREATE_URL: API.baseUrl + '/b2b-user/companies/addresses/create',
  ADDRESS_EDIT_URL: API.baseUrl + '/b2b-user/companies/addresses/edit',
  ADDRESS_DELETE_URL: API.baseUrl + '/b2b-user/companies/addresses/%s/delete',

  COUNTRY_GET_URL: API.baseUrl + '/b2b-user/companies/countries?appKey=%s',
  COUNTRY_STATE_URL: API.baseUrl + '/b2b-user/companies/countries/%s/states?appKey=%s',

  CUSTOMER_GROUP_URL: API.baseUrl + '/b2b-user/users/groups?appKey=%s&pageNumber=%s&pageSize=%s',
  CUSTOMER_GROUP_SEARCH_URL: API.baseUrl + '/b2b-user/users/groups/search?appKey=%s&pageNumber=%s&pageSize=%s&name=%s',

  IMPORT_URL: API.baseUrl + '/b2b-user/integrate/users',
  IMPORT_STATUS_URL: API.baseUrl + '/b2b-user/integrate/users/progress?appKey=%s',

  STORE_LANGUAGE_URL: API.baseUrl + '/b2b-user/stores/languages?pageNumber=%s&pageSize=%s',
  STORES_URL: API.baseUrl + '/b2b-user/stores/detail?pageNumber=%s&pageSize=%s',
  STORE_URL: API.baseUrl + '/b2b-user/stores',
  STORE_ENABLE_URL: API.baseUrl + '/b2b-user/stores/enable',
  STORE_DISABLE_URL: API.baseUrl + '/b2b-user/stores/disable',
  STORE_DELETE_URL: API.baseUrl + '/b2b-user/stores/%s',
  STORE_EDIT_URL: API.baseUrl + '/b2b-user/stores',
  STORE_VALIDATE_URL: API.baseUrl + '/b2b-user/stores/validate',
  INTEGRATE_URL: API.integrateUserUrl + '/batch/users/import/async',
};
