import {CompanyConstants} from "../../constants/company";

export function companyDelete(state = {}, action) {
  switch (action.type) {
    case CompanyConstants.DELETE_REQUEST:
      return {
        loading: true,
        companyIds: action.companyIds
      };
    case CompanyConstants.DELETE_SUCCESS:
      return {
        success: true
      };
    case CompanyConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    case CompanyConstants.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
