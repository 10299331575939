import {AdminConstants} from "../../constants/admin";

export function adminChangePassword(state = {}, action) {
  switch (action.type) {
    case AdminConstants.CHANGE_PASSWORD_REQUEST:
      return {
        loading: true
      };
    case AdminConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        success: true
      };
    case AdminConstants.CHANGE_PASSWORD_FAILURE:
      return {};
    case AdminConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
