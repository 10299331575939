import React from "react";

class LoadingIndicator extends React.Component<any, any>{
  render(): React.ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="142" height="94" viewBox="0 0 83 55"
           style={{position:'absolute', left: '50%', top:'50%', transform:'translateX(-50%) translateY(-50%)'}}>
        <g fill="#4f4f4f">
          <path d="M2.2,32.9 L3.9,31 C4.6,31.8 5.3,32.2 6.1,32.2 C7.1,32.2 7.7,31.6 7.7,30.2 L7.7,22.4 L10.4,22.4 L10.4,30.3 C10.4,31.7 10,32.8 9.3,33.5 C8.6,34.2 7.5,34.6 6.2,34.6 C4.3,34.7 3,33.9 2.2,32.9 Z"></path>
          <polygon points="13.1 22.5 16 22.5 19.2 27.6 22.4 22.5 25.3 22.5 25.3 34.6 22.7 34.6 22.7 26.7 19.3 31.8 19.2 31.8 15.8 26.7 15.8 34.5 13.2 34.5 13.2 22.5"></polygon>
          <path d="M32.2,22.4 L34.6,22.4 L39.8,34.5 L37,34.5 L35.9,31.8 L30.8,31.8 L29.7,34.5 L27,34.5 L32.2,22.4 Z M35,29.5 L33.4,25.6 L31.8,29.5 L35,29.5 Z"></path>
          <polygon points="41.7 22.5 44.1 22.5 49.7 29.9 49.7 22.5 52.3 22.5 52.3 34.6 50 34.6 44.2 26.9 44.2 34.6 41.6 34.6 41.6 22.5"></polygon>
          <path d="M54.7 28.6L54.7 28.6C54.7 25.1 57.4 22.3 61 22.3 63.2 22.3 64.5 22.9 65.7 24L64 26C63.1 25.2 62.2 24.8 60.8 24.8 58.9 24.8 57.3 26.5 57.3 28.6L57.3 28.6C57.3 30.8 58.8 32.4 61 32.4 62 32.4 62.8 32.2 63.5 31.7L63.5 30 61 30 61 27.7 66.2 27.7 66.2 32.9C65 34 63.3 34.8 61.1 34.8 57.3 34.8 54.7 32.1 54.7 28.6zM68.2 28.6L68.2 28.6C68.2 25.1 70.9 22.3 74.6 22.3 78.3 22.3 81 25.1 81 28.5L81 28.5C81 31.9 78.3 34.7 74.6 34.7 70.9 34.8 68.2 32 68.2 28.6zM78.3 28.6L78.3 28.6C78.3 26.5 76.8 24.8 74.6 24.8 72.5 24.8 71 26.5 71 28.6L71 28.6C71 30.7 72.5 32.4 74.7 32.4 76.9 32.4 78.3 30.6 78.3 28.6zM33.5 46.1L34.4 45.4C34.7 45.8 35 46 35.6 46 36.2 46 36.7 45.6 36.7 45.2 36.7 44.8 36.3 44.5 35.6 44.5L35.3 44.5 35.3 43.7 37 42.4 34.8 42.4 35.1 41.3 38.9 41.3 38.6 42.2 36.8 43.6C37.5 43.8 38 44.2 38 45 38 46.1 37 47 35.6 47 34.6 47.2 33.9 46.8 33.5 46.1zM39.6 46.7C39.2 46.3 39 45.8 39 45.1 39 44 39.4 42.9 40.1 42.2 40.6 41.7 41.3 41.3 42.2 41.3 42.9 41.3 43.5 41.6 44 42L43.3 42.9C43 42.6 42.6 42.5 42.2 42.5 41.7 42.5 41.4 42.6 41.1 42.9 40.9 43.1 40.6 43.5 40.5 43.9 40.7 43.7 41.3 43.4 41.8 43.4 42.8 43.4 43.5 44 43.5 45 43.5 46.1 42.5 47.2 41.1 47.2 40.5 47.2 40 47 39.6 46.7zM42.2 45.2C42.2 44.7 41.9 44.5 41.3 44.5 40.6 44.5 40.2 44.9 40.2 45.5 40.2 45.7 40.3 45.9 40.4 46 40.6 46.2 40.8 46.2 41.1 46.2 41.8 46.1 42.2 45.6 42.2 45.2zM44.3 44.8C44.3 43.1 45.4 41.3 47.3 41.3 48.6 41.3 49.5 42.2 49.5 43.7 49.5 45.4 48.4 47.2 46.5 47.2 45.2 47.2 44.3 46.3 44.3 44.8zM48.3 43.7C48.3 42.9 47.9 42.5 47.3 42.5 46.3 42.5 45.6 43.8 45.6 44.9 45.6 45.7 46 46.1 46.6 46.1 47.6 46.1 48.3 44.7 48.3 43.7z"></path>
          <path d="M64.8 38.6C64.4 39.5 63.9 40.4 63.3 41.1 62.5 42.1 61.5 42.9 60.3 43.6 55 46.6 50.6 49.1 46.4 51.4 44.7 52.3 43.1 52.8 41.4 52.8 39.6 52.8 37.8 52.3 36 51.2 31.7 48.7 27.2 46 22.7 43.3 21 42.3 19.7 41 18.9 39.5 18.7 39.2 18.6 38.9 18.5 38.6L16.1 38.6C16.3 39.3 16.6 40 17 40.6 18 42.4 19.6 44 21.6 45.2 26.1 47.9 30.6 50.6 34.9 53.1 37 54.4 39.2 55 41.4 55 43.4 55 45.5 54.4 47.5 53.3 51.7 51 56.1 48.5 61.4 45.5 64.3 43.9 66.2 41.5 67.1 38.5L64.8 38.5 64.8 38.6zM17.9 18.6C18.1 17.5 18.4 16.5 18.9 15.6 19.6 14.3 20.7 13.1 22.1 12.1 22.6 11.7 23.2 11.4 23.9 11 24 10.9 24.1 10.9 24.2 10.8 25.7 10 27.2 9.1 28.7 8.3 31.1 7 33.5 5.7 36 4.3 37.9 3.3 39.4 2.7 40.9 2.6 41.2 2.6 41.5 2.6 41.8 2.6 43.6 2.6 45.4 3.1 47.1 4.1L47.3 4.2C51.7 6.8 56.2 9.4 60.6 12.1 62.3 13.2 63.6 14.5 64.5 16.1 64.9 16.9 65.2 17.7 65.4 18.7L67.7 18.7C67.5 17.4 67.1 16.2 66.5 15.1 65.5 13.1 63.9 11.5 61.8 10.2 57.4 7.5 52.9 4.8 48.5 2.3L48.3 2.2C46.2 1 44.1.4 41.9.4 41.5.4 41.1.4 40.7.5 39.7.6 38.7.8 37.7 1.2 36.8 1.5 35.9 1.9 35 2.5 32.6 3.9 30.1 5.3 27.7 6.6 26.2 7.4 24.7 8.3 23.2 9.1 23 9 22.9 9 22.8 9.1 22.2 9.5 21.5 9.8 20.9 10.3 19.2 11.5 17.9 12.9 17 14.6 16.3 15.8 15.9 17.2 15.7 18.7L17.9 18.7 17.9 18.6z"></path>
          <rect width="82.8" height="1" x=".2" y="18.4"></rect>
          <rect width="82.8" height="1" x=".2" y="38.1"></rect>
        </g>
      </svg>
    );
  }
}

export default LoadingIndicator;