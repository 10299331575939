import {SettingConstants} from "../../constants/setting";

export function settingsLoader(state = {}, action) {
  switch (action.type) {
    case SettingConstants.GET_REQUEST:
      return {
        loading: true
      };
    case SettingConstants.GET_SUCCESS:
      return {
        settings: action.settings,
        complete: true
      };
    case SettingConstants.GET_FAILURE:
      return {
        error: action.error,
        complete: true
      };
    case SettingConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
