import React, {Component} from "react";
import {connect, ConnectedProps} from "react-redux";

import FormChangeForgotPassword from "../../components/FormChangeForgotPassword";

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

class ForgotPasswordPage extends Component<Props, any> {
  render() {
    return (
      <FormChangeForgotPassword/>
    )
  }
}

export default connector(ForgotPasswordPage);
