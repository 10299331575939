import {CompanyConstants} from "../../constants/company";
import {AdminConstants} from "../../constants/admin";
import _ from "lodash";

export function companySearch(state = {companies: [], pagination: {}, filters: {}}, action) {
  switch (action.type) {
    case CompanyConstants.SEARCH_REQUEST:
      return {
        loading: true,
        params: action.params,
        companies: state.companies,
        pagination: state.pagination,
        filters: state.filters
      }
    case CompanyConstants.SEARCH_SUCCESS:
      return {
        success: true,
        companies: action.result.isManageTable
          ? action.result.list
          : (action.result.pagination.pageNumber === 1 ?
                _.orderBy(action.result.list, [item => item.name.toLowerCase()], ['asc'])
                 : _.orderBy([...state.companies, ...action.result.list], [item => item.name.toLowerCase()], ['asc'])
                ),
        pagination: action.result.pagination,
        filters: action.result.filters,
        full: action.result.list.length === 0
      }
    case CompanyConstants.SEARCH_FAILURE:
      return {
        error: action.error,
        companies: [],
        pagination: state.pagination,
        filters: state.filters
      }
    case CompanyConstants.CLEAR_ERRORS:
      return {
        companies: state.companies,
        pagination: state.pagination,
        filters: state.filters
      }
    case AdminConstants.LOGOUT:
    case CompanyConstants.CLEAR_RESULTS:
      return {
        companies: [],
        pagination: {
          pageNumber: 1,
          pageSize: 20,
          total: 0
        },
        filters: {}
      }
    case CompanyConstants.DELETE_SUCCESS:
      return {
        companies: state.companies.filter((company: any) => action.companyIds.indexOf(company.id) === -1),
        pagination: state.pagination,
        filters: state.filters
      }
    default:
      return state;
  }
}
