import {StoreConstants} from "../../constants/store";

export function storeAdd(state = {}, action) {
  switch (action.type) {
    case StoreConstants.ADD_REQUEST:
      return {
        loading: true,
        store: action.store
      };
    case StoreConstants.ADD_SUCCESS:
      return {
        success: true,
        store: action.result.store
      };
    case StoreConstants.ADD_FAILURE:
      return {
        error: action.error
      };
    case StoreConstants.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
