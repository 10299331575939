import {AdminConstants} from "../../constants/admin";

export function adminChangePasswordForgot(state = {}, action) {
  switch (action.type) {
    case AdminConstants.CHANGE_PASSWORD_FORGOT_REQUEST:
      return {
        requesting: true
      };
    case AdminConstants.CHANGE_PASSWORD_FORGOT_SUCCESS:
      return {};
    case AdminConstants.CHANGE_PASSWORD_FORGOT_FAILURE:
      return {};
    default:
      return state;
  }
}
