export const AddressConstants = {
    SAVE_REQUEST: 'ADDRESS_SAVE_REQUEST',
    SAVE_SUCCESS: 'ADDRESS_SAVE_SUCCESS',
    SAVE_FAILURE: 'ADDRESS_SAVE_FAILURE',

    DELETE_REQUEST: 'ADDRESS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ADDRESS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ADDRESS_DELETE_FAILURE',

    SET_ADDRESS: 'ADDRESS_SET',

    CLEAR_ERRORS: 'ADDRESS_CLEAR_ERRORS',
    CLEAR_RESULTS: 'ADDRESS_CLEAR_RESULTS',

    SAVE_BILIING_ADDRESS_LOCAL: 'SAVE_BILIING_ADDRESS_LOCAL',
    ADD_SHIPPING_ADDRESS_LOCAL: 'ADD_SHIPPING_ADDRESS_LOCAL',
    EDIT_SHIPPING_ADDRESS_LOCAL: 'EDIT_SHIPPING_ADDRESS_LOCAL',
    DEL_SHIPPING_ADDRESS_LOCAL: 'DEL_SHIPPING_ADDRESS_LOCAL',
    SET_ADDRESS_LOCAL: 'SET_ADDRESS_LOCAL',
    CLEAR_ERRORS_ADDRESS_LOCAL: 'CLEAR_ERRORS_ADDRESS_LOCAL',
    CLEAR_RESULTS_SHIPPING_ADDRESS_LOCAL: 'CLEAR_RESULTS_SHIPPING_ADDRESS_LOCAL',
};