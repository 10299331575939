import {ApiConstants} from "../constants/api";
import {AppKey} from "../constants";
import {HeaderKey} from "../constants";
import localStore from "../utils/localStorage";

export const AdminServices = {
  login,
  resetPassword,
  changePasswordForgot,
  changePassword,
  updatePassword,
  editProfile
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'email': username,
      'password': password
    })
  };

  return fetch(ApiConstants.ADMIN_LOGIN_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.user) {
            return Promise.resolve({admin: data.user, kpData: data.kpData});
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function resetPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'email': email
    })
  };

  return fetch(ApiConstants.ADMIN_FORGOT_PASSWORD_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve(email);
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function changePasswordForgot(token, password, confirm) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'token': token,
      'password': password,
      'confirmPassword': confirm
    })
  };

  return fetch(ApiConstants.ADMIN_CHANGE_PASSWORD_FORGOT_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve();
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function changePassword(kpData: any, values: any) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      [HeaderKey.JM360_KP_VER]: kpData.keypairVersion,
      [HeaderKey.JM360_KP]: kpData.keypairData,
      [HeaderKey.JM360_KP_EXP]: kpData.keyPairExpiration
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'currentPassword': values.currentPassword || '',
      'password': values.password || '',
      'confirmPassword': values.confirmPassword || ''
    })
  };

  return fetch(ApiConstants.ADMIN_CHANGE_PASSWORD_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            //data.item.resetPassword = 0;
            return Promise.resolve(data.item);
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function updatePassword(kpData: any, values: any) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      [HeaderKey.JM360_KP_VER]: kpData.keypairVersion,
      [HeaderKey.JM360_KP]: kpData.keypairData,
      [HeaderKey.JM360_KP_EXP]: kpData.keyPairExpiration
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'currentPassword': values.currentPassword || '',
      'password': values.password || '',
      'confirmPassword': values.confirmPassword || ''
    })
  };

  return fetch(ApiConstants.ADMIN_UPDATE_PASSWORD_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve();
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function editProfile(kpData, data) {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'manager': {
        "id": data['id'] || '',
        "firstName": data['firstName'] || '',
        "lastName": data['lastName'] || '',
        "email": data['email'] || '',
        "phone": data['phone'] || '',
        "currentPassword": data['currentPassword'] || '',
        "password": data['password'] || '',
        "confirmPassword": data['confirmPassword'] || '',
      }
    })
  };

  return fetch(ApiConstants.ADMIN_EDIT_PROFILE_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.item) {
            return Promise.resolve(data.item);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}