export const CompanyConstants = {
  SEARCH_REQUEST: 'COMPANY_SEARCH_REQUEST',
  SEARCH_SUCCESS: 'COMPANY_SEARCH_SUCCESS',
  SEARCH_FAILURE: 'COMPANY_SEARCH_FAILURE',

  GET_BY_USER_REQUEST: 'COMPANY_BY_USER_REQUEST',
  GET_BY_USER_SUCCESS: 'COMPANY_BY_USER_SUCCESS',
  GET_BY_USER_FAILURE: 'COMPANY_BY_USER_FAILURE',

  GET_BY_ID_REQUEST: 'COMPANY_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'COMPANY_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'COMPANY_BY_ID_FAILURE',

  DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

  SAVE_REQUEST: 'COMPANY_SAVE_REQUEST',
  SAVE_SUCCESS: 'COMPANY_SAVE_SUCCESS',
  SAVE_FAILURE: 'COMPANY_SAVE_FAILURE',

  CLEAR_ERRORS: 'COMPANY_CLEAR_ERRORS',
  CLEAR_RESULTS: 'COMPANY_CLEAR_RESULTS',
};