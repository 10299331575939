import React from "react";
import {Popover, Button} from "antd";
import {CaretDownOutlined, EditOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {AdminActions} from "../actions/admin";
import util from "util";
import {Link} from "react-router-dom";
import * as Routes from "../constants/route";
import "./AdminProfilePopover.scss";

class AdminProfilePopover extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  render(): React.ReactNode {
    const {visible} = this.state;
    const {admin, logout} = this.props;

    const name = util.format("%s %s", admin.firstName || '', admin.lastName || '');

    const content = (
      <div className={'admin-popover'}>
        <div className={'admin-popover-title'}>
          <b>{name}</b>
          <Link to={Routes.ADMIN_EDIT_PROFILE_URL} onClick={() => this.setState({visible: false})}>
            <EditOutlined/>
          </Link>
        </div>
        <div className={'admin-popover-content'}>
          <p>{admin.email || ''}</p>
          <p>{admin.phone || ''}</p>
        </div>
        <div className={'admin-popover-action'}>
          <Link to={Routes.ADMIN_CHANGE_PASSWORD_URL} onClick={() => this.setState({visible: false})}>
            <Button className={'admin-logout-btn'}>Change Password</Button>
          </Link>
        </div>
        <div className={'admin-popover-action'}>
          <Button className={'admin-logout-btn'} onClick={logout}>Logout</Button>
        </div>
      </div>
    );

    return (
      <Popover
        placement={'bottomRight'}
        content={content}
        trigger={'click'}
        visible={visible}
        onVisibleChange={(visible) => this.setState({visible: visible})}
      >
        <Button size={'small'} className={'admin-btn'}>{name}<CaretDownOutlined/></Button>
      </Popover>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin: state.adminLogin.admin
  }
}

const mapDispatchToProps = {
  logout: AdminActions.logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProfilePopover);