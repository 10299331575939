import {add, formatISO} from "date-fns";
import {AdminConstants} from "../../constants/admin";
import {ADMIN_TIMEOUT} from "../../constants";
import localStore from "../../utils/localStorage";

function getExpirationTime() {
  return formatISO(add(new Date(), {seconds: ADMIN_TIMEOUT}));
}

export function adminLogin(state = {}, action) {
  switch (action.type) {
    case AdminConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        admin: action.admin
      };
    case AdminConstants.LOGIN_SUCCESS:
        // const keypairData = action.kpData;
        const header = {
            'jm360_kp': action.kpData.keypairData,
            'jm360_kp_ver': action.kpData.keypairVersion,
        };
        localStore.setObject('headerAPI', header);

      return {
        loggedIn: true,
        expirationTime: getExpirationTime(),
        admin: action.admin,
        kpData: action.kpData
      };
    case AdminConstants.UPDATE_EXPIRATION_TIME:
      return {
        ...state,
        ...{expirationTime: getExpirationTime()}
      };
    case AdminConstants.EXPIRE_SESSION:
      return {
        sessionExpired: true
      };
    case AdminConstants.CLEAR_EXPIRE_SESSION:
      return {};
    case AdminConstants.LOGIN_FAILURE:
      return {};
    case AdminConstants.LOGOUT:
        localStorage.clear();
        console.log('logout');
      return {};
    case AdminConstants.UPDATE:
      return {
        ...state,
        ...{admin: action.admin}
      };
    default:
      return state;
  }
}
