import { UserConstants } from "../../constants/user";

export function agentsGetByUser(state = { users: [] }, action) {
  switch (action.type) {
    case UserConstants.AGGENTS_GET_BY_USER_REQUEST:
      return {
        loading: true,
        userId: action.userId,
        users: [],
      };
    case UserConstants.AGGENTS_GET_BY_USER_SUCCESS:
      return {
        users: action.users,
        loading: false,
      };
    case UserConstants.AGGENTS_GET_BY_USER_FAILURE:
      return {
        error: action.error,
        users: [],
      };
    // case UserConstants.CLEAR_ERRORS:
    //   return {
    //     users: state.users
    //   };
    case UserConstants.CLEAR_RESULTS:
      return {
        users: [],
      };
    default:
      return state;
  }
}
