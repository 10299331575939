import _ from "lodash";
import {AddressConstants} from "../../constants/address";
import {AdminConstants} from "../../constants/admin";

export function addressLocal(state = {
    shippingAddresses: [],
    billingAddress: {},
    success: false
}, action) {
    switch (action.type) {

        case AddressConstants.DELETE_REQUEST:
            return {
                loading: true,
                address: action.address,
                billingAddress: state.billingAddress,
                shippingAddresses: state.shippingAddresses.filter((a: any, index) => index != action.index),
            };
        case AddressConstants.DELETE_SUCCESS:
            return {
                deleteSuccess: true,
                billingAddress: state.billingAddress,
                shippingAddresses: state.shippingAddresses
            };
        case AddressConstants.DELETE_FAILURE:
            return {
                error: action.error,
                billingAddress: state.billingAddress,
                shippingAddresses: state.shippingAddresses
            };

        case AddressConstants.ADD_SHIPPING_ADDRESS_LOCAL:
            return {
                success: true,
                // address: action.address,
                billingAddress: state.billingAddress,
                shippingAddresses: [...state.shippingAddresses, action.address]
            };
        case AddressConstants.DEL_SHIPPING_ADDRESS_LOCAL:
            return {
                billingAddress: state.billingAddress,
                shippingAddresses: state.shippingAddresses.filter((a: any, index) => index != action.index),
                deleteSuccess: true
            };
        case AddressConstants.EDIT_SHIPPING_ADDRESS_LOCAL:
            let addressItems = state.shippingAddresses.map((item, itemIndex) =>{
                return  action.index == itemIndex ? action.address : item
            });

            return {
                success: true,
                billingAddress: state.billingAddress,
                shippingAddresses: addressItems
            };
        case AddressConstants.SAVE_BILIING_ADDRESS_LOCAL:
            // let shippingAdd = [action.billingAddress, ...state.shippingAddresses];
            // if (!_.isEmpty(state.billingAddress)) {
            //     shippingAdd = state.shippingAddresses.map((address, index) =>
            //         index == 0 ? action.billingAddress : address
            //     );
            // }
            return {
                success: true,
                billingAddress: action.billingAddress,
                shippingAddresses: state.shippingAddresses
                // shippingAddresses: shippingAdd
            };
        case AddressConstants.SET_ADDRESS_LOCAL:
            return {
                billingAddress: action.billingAddress,
                shippingAddresses: action.shippingAddresses
            };
        case AddressConstants.CLEAR_ERRORS_ADDRESS_LOCAL:
            return {
                billingAddress: state.billingAddress,
                shippingAddresses: state.shippingAddresses
            };
        case AddressConstants.CLEAR_RESULTS_SHIPPING_ADDRESS_LOCAL:
            return {
                shippingAddresses: [],
                billingAddress: {}
            };
        case AdminConstants.LOGOUT:
            return {};
        default:
            return state;
    }
}
