import {UserConstants} from "../../constants/user";

export function userGetByEmail(state = {}, action) {
  switch (action.type) {
    case UserConstants.GET_BY_EMAIL_REQUEST:
      return {
        loading: true,
        email: action.email
      };
    case UserConstants.GET_BY_EMAIL_SUCCESS:
      return {
        user: action.user
      };
    case UserConstants.GET_BY_EMAIL_FAILURE:
      return {
        error: action.error
      };
    case UserConstants.CLEAR_ERRORS:
      return {};
    case UserConstants.CLEAR_RESULTS:
      return {};
    default:
      return state;
  }
}
