import {AdminConstants} from "../constants/admin";
import {AdminServices} from "../services/admin";
import {AlertActions} from "./alert";
import {history} from "../helpers/store";

export const AdminActions = {
  login,
  logout,
  resetPassword,
  changePasswordForgot,
  changePassword,
  editProfile,
  clear,
  updateExpirationTime,
  expireSession,
  clearExpireSession
};

function login(username: string, password: string) {
  return dispatch => {
    dispatch(request({username}));

    AdminServices.login(username, password)
      .then(
        (data: any) => {
          dispatch(success(data.admin, data.kpData));
        },
        error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
  };

  function request(admin) {
    return {type: AdminConstants.LOGIN_REQUEST, admin}
  }

  function success(admin, kpData) {
    return {type: AdminConstants.LOGIN_SUCCESS, admin, kpData}
  }

  function failure(error) {
    return {type: AdminConstants.LOGIN_FAILURE, error}
  }
}

function logout() {
  return {type: AdminConstants.LOGOUT}
}

function resetPassword(email) {
  return dispatch => {
    dispatch(request(email));

    AdminServices.resetPassword(email)
      .then(email => {
        dispatch(success(email));
      }, error => {
        dispatch(failure(error));
        dispatch(AlertActions.error(error));
      })
  };

  function request(email) {
    return {type: AdminConstants.RESET_PASSWORD_REQUEST, email}
  }

  function success(email) {
    return {type: AdminConstants.RESET_PASSWORD_SUCCESS, email}
  }

  function failure(error) {
    return {type: AdminConstants.RESET_PASSWORD_FAILURE, error}
  }
}

function changePasswordForgot(token, password, confirm) {
  return dispatch => {
    dispatch(request());

    AdminServices.changePasswordForgot(token, password, confirm)
      .then(() => {
        dispatch(success());
        history.push('/login');
        dispatch(AlertActions.success('An instruction has been sent to your email'));
      }, error => {
        dispatch(failure(error));
        dispatch(AlertActions.error(error));
      })
  };

  function request() {
    return {type: AdminConstants.CHANGE_PASSWORD_FORGOT_REQUEST}
  }

  function success() {
    return {type: AdminConstants.CHANGE_PASSWORD_FORGOT_SUCCESS}
  }

  function failure(error) {
    return {type: AdminConstants.CHANGE_PASSWORD_FORGOT_FAILURE, error}
  }
}

function changePassword(kpData, values) {
  return dispatch => {
    dispatch(request());

    if (values.currentPassword) {
      AdminServices.updatePassword(kpData, values)
        .then(() => {
          dispatch(success());
          dispatch(AlertActions.success('Password has been updated!'));
        }, error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
    } else {
      AdminServices.changePassword(kpData, values)
        .then(admin => {
          dispatch(success());
          setTimeout(() => {
            dispatch(update(admin));
          }, 1000);
        }, error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
    }
  };

  function request() {
    return {type: AdminConstants.CHANGE_PASSWORD_REQUEST}
  }

  function success() {
    return {type: AdminConstants.CHANGE_PASSWORD_SUCCESS}
  }

  function update(admin) {
    return {type: AdminConstants.UPDATE, admin}
  }

  function failure(error) {
    return {type: AdminConstants.CHANGE_PASSWORD_FAILURE, error}
  }
}

function editProfile(kpData, data) {
  return dispatch => {
    dispatch(request(data));

    AdminServices.editProfile(kpData, data)
      .then(
        admin => {
          dispatch(success());
          dispatch(update(admin, kpData));
          dispatch(AlertActions.success('Profile has been updated'));
        },
        error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
  };

  function request(admin) {
    return {type: AdminConstants.EDIT_PROFILE_REQUEST, admin}
  }

  function success() {
    return {type: AdminConstants.EDIT_PROFILE_SUCCESS}
  }

  function update(admin, kpData) {
    return {type: AdminConstants.LOGIN_SUCCESS, admin, kpData}
  }

  function failure(error) {
    return {type: AdminConstants.EDIT_PROFILE_FAILURE, error}
  }
}

function clear() {
  return {type: AdminConstants.CLEAR}
}

function updateExpirationTime() {
  return {type: AdminConstants.UPDATE_EXPIRATION_TIME}
}

function expireSession() {
  return {type: AdminConstants.EXPIRE_SESSION}
}

function clearExpireSession() {
  return {type: AdminConstants.CLEAR_EXPIRE_SESSION}
}
