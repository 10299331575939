export const AdminConstants = {
  LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'ADMIN_LOGIN_FAILURE',

  UPDATE_EXPIRATION_TIME: 'ADMIN_UPDATE_EXPIRATION_TIME',
  EXPIRE_SESSION: 'ADMIN_EXPIRE_SESSION',
  CLEAR_EXPIRE_SESSION: 'ADMIN_CLEAR_EXPIRE_SESSION',

  RESET_PASSWORD_REQUEST: 'ADMIN_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'ADMIN_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'ADMIN_RESET_PASSWORD_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'ADMIN_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'ADMIN_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'ADMIN_CHANGE_PASSWORD_FAILURE',

  CHANGE_PASSWORD_FORGOT_REQUEST: 'ADMIN_CHANGE_PASSWORD_FORGOT_REQUEST',
  CHANGE_PASSWORD_FORGOT_SUCCESS: 'ADMIN_CHANGE_PASSWORD_FORGOT_SUCCESS',
  CHANGE_PASSWORD_FORGOT_FAILURE: 'ADMIN_CHANGE_PASSWORD_FORGOT_FAILURE',

  EDIT_PROFILE_REQUEST: 'ADMIN_EDIT_PROFILE_REQUEST',
  EDIT_PROFILE_SUCCESS: 'ADMIN_EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAILURE: 'ADMIN_EDIT_PROFILE_FAILURE',

  LOGOUT: 'ADMIN_LOGOUT',
  CLEAR: 'ADMIN_CLEAR',
  UPDATE: 'ADMIN_UPDATE'
};