import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {createLogger} from "redux-logger";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {rootReducer, createReduxHistory, routerMiddleware} from "../reducers";

console.log('Version', process.env.REACT_APP_VERSION);

const logger = createLogger({
  collapsed: true
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['adminLogin']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(routerMiddleware, thunk, logger));
const persistor = persistStore(store);
const history = createReduxHistory(store);

export {store, persistor, history};