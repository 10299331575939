import util from "util";
import {ApiConstants} from "../constants/api";
import {AppKey} from "../constants";
import localStore from "../utils/localStorage";

export const SettingServices = {
  getSettings,
  saveSettings
}

function getSettings() {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    }
  };

  const url = util.format(ApiConstants.SETTINGS_URL, AppKey);

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve(data.setting);
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function saveSettings(values: any = {}) {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'setting': values
    })
  };

  return fetch(ApiConstants.SETTINGS_SAVE_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve(data.setting);
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}