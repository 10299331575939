import {ImportConstants} from "../../constants/import";

export function importStatus(state = {status: '', startedAt: null, updatedAt: null}, action) {
  switch (action.type) {
    case ImportConstants.CHECK_REQUEST:
      return {
        loading: true,
        status: state.status,
        startedAt: state.startedAt,
        updatedAt: state.updatedAt
      };
    case ImportConstants.CHECK_SUCCESS:
      return {
        status: action.result.status,
        message: action.result.message,
        startedAt: action.result.startedAt,
        updatedAt: action.result.updatedAt
      };
    case ImportConstants.CHECK_FAILURE:
      return {
        error: action.error
      };
    case ImportConstants.CLEAR:
      return {};
    case ImportConstants.IMPORT_SUCCESS:
      return {
        status: 'inprogress',
        startedAt: state.startedAt,
        updatedAt: state.updatedAt
      };
    default:
      return state;
  }
}
