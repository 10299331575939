import {CompanyConstants} from "../../constants/company";

export function companyById(state = {company: {}}, action) {
  switch (action.type) {
    case CompanyConstants.GET_BY_ID_REQUEST:
      return {
        loading: true,
        companyId: action.companyId
      }
    case CompanyConstants.GET_BY_ID_SUCCESS:
      return {
        success: true,
        company: action.company
      }
    case CompanyConstants.GET_BY_ID_FAILURE:
      return {
        error: action.error
      }
    case CompanyConstants.CLEAR_ERRORS:
      return {
        company: state.company
      }
    case CompanyConstants.CLEAR_RESULTS:
      return {}
    default:
      return state;
  }
}
