import {CompanyConstants} from "../../constants/company";

export function companySave(state = {}, action) {
  switch (action.type) {
    case CompanyConstants.SAVE_REQUEST:
      return {
        loading: true,
        values: action.values
      };
    case CompanyConstants.SAVE_SUCCESS:
      return {
        success: true
      };
    case CompanyConstants.SAVE_FAILURE:
      return {
        error: action.error
      };
    case CompanyConstants.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
