import React from 'react';
import {render} from 'react-dom';
import {Provider} from "react-redux";
import 'antd/dist/antd.css';
import {PersistGate} from "redux-persist/integration/react";

import {store, persistor} from "./helpers/store";
import App from "./app";

render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <App/>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
