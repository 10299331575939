import {ImportConstants} from "../../constants/import";

export function importRunner(state = {started: false}, action) {
  switch (action.type) {
    case ImportConstants.IMPORT_REQUEST:
      return {
        loading: true
      };
    case ImportConstants.IMPORT_SUCCESS:
      return {
        started: true
      };
    case ImportConstants.IMPORT_FAILURE:
      return {
        error: action.error
      };
    case ImportConstants.CLEAR:
      return {
        started: state.started
      };
    case ImportConstants.CHECK_SUCCESS:
      return {
        started: action.result.status === 'inprogress'
      };
    default:
      return state;
  }
}
