import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Menu} from "antd";
import {SolutionOutlined, UserOutlined, SettingOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";

import {history} from "../helpers/store";
import * as Routes from "../constants/route";

const {SubMenu} = Menu;

function mapStateToProps(state) {
  return {
    router: state.router,
    settings: state.settingsLoader.settings || {}
  };
}

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

class MenuSider extends React.Component<Props, any> {
  render(): React.ReactNode {
    const {settings} = this.props;

    return (
      <Menu
        selectedKeys={[history.location.pathname]}
        defaultOpenKeys={[history.location.pathname.split('/')[1]]}
        mode={'inline'} theme={'dark'}
      >
        <SubMenu key={'user'} title={<span><UserOutlined/>Users</span>}>
          <Menu.Item key={Routes.USER_MANAGE_URL}>
            <NavLink to={Routes.USER_MANAGE_URL}>Manage Users</NavLink>
          </Menu.Item>
          <Menu.Item key={Routes.USER_CREATE_URL}>
            <NavLink to={Routes.USER_CREATE_URL}>Create User</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu key={'company'} title={<span><SolutionOutlined/>Company</span>}>
          <Menu.Item key={Routes.COMPANY_MANAGE_URL}>
            <NavLink to={Routes.COMPANY_MANAGE_URL}>Manage Companies</NavLink>
          </Menu.Item>
          {settings.autoSynchUser ? null : (
            <Menu.Item key={Routes.COMPANY_CREATE_URL}>
              <NavLink to={Routes.COMPANY_CREATE_URL}>Create Company</NavLink>
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu key={'settings'} title={<span><SettingOutlined/>Settings</span>}>
          <Menu.Item key={Routes.SETTINGS_URL}>
            <NavLink to={Routes.SETTINGS_URL}>Integration Settings</NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}

export default connector(MenuSider);