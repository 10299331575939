import {StoreConstants} from "../../constants/store";

export function storeLanguage(state = {languages: [], pagination: {pageNumber: 1, pageSize: 24}}, action) {
  switch (action.type) {
    case StoreConstants.LANGUAGE_REQUEST:
      return {
        loading: true,
        languages: state.languages,
        pagination: state.pagination
      };
    case StoreConstants.LANGUAGE_SUCCESS:
      return {
        languages: action.result.languages,
        pagination: action.result.pagination
      };
    case StoreConstants.LANGUAGE_FAILURE:
      return {
        error: action.error,
        languages: [],
        pagination: state.pagination
      };
    case StoreConstants.CLEAR_ERRORS:
      return {
        languages: state.languages,
        pagination: state.pagination
      };
    default:
      return state;
  }
}
