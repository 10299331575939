export const StoreConstants = {
  GET_REQUEST: 'STORE_GET_REQUEST',
  GET_SUCCESS: 'STORE_GET_SUCCESS',
  GET_FAILURE: 'STORE_GET_FAILURE',

  ADD_REQUEST: 'STORE_ADD_REQUEST',
  ADD_SUCCESS: 'STORE_ADD_SUCCESS',
  ADD_FAILURE: 'STORE_ADD_FAILURE',

  LANGUAGE_REQUEST: 'STORE_LANGUAGE_REQUEST',
  LANGUAGE_SUCCESS: 'STORE_LANGUAGE_SUCCESS',
  LANGUAGE_FAILURE: 'STORE_LANGUAGE_FAILURE',

  ENABLE_REQUEST: 'STORE_ENABLE_REQUEST',
  ENABLE_SUCCESS: 'STORE_ENABLE_SUCCESS',
  ENABLE_FAILURE: 'STORE_ENABLE_FAILURE',

  DISABLE_REQUEST: 'STORE_DISABLE_REQUEST',
  DISABLE_SUCCESS: 'STORE_DISABLE_SUCCESS',
  DISABLE_FAILURE: 'STORE_DISABLE_FAILURE',

  DELETE_REQUEST: 'STORE_DELETE_REQUEST',
  DELETE_SUCCESS: 'STORE_DELETE_SUCCESS',
  DELETE_FAILURE: 'STORE_DELETE_FAILURE',

  EDIT_REQUEST: 'STORE_EDIT_REQUEST',
  EDIT_SUCCESS: 'STORE_EDIT_SUCCESS',
  EDIT_FAILURE: 'STORE_EDIT_FAILURE',

  CLEAR_ERRORS: 'STORE_CLEAR_ERRORS'
};