import React, {useEffect} from "react";
import {Route, Redirect} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";

import AuthLayout from "../layouts/AuthLayout";
import * as Routes from "../constants/route";
import {AlertActions} from "../actions/alert";
import {AdminActions} from "../actions/admin";

function mapStateToProps(state) {
  return {
    loggedIn: state.adminLogin.loggedIn,
    admin: state.adminLogin.admin,
    router: state.router
  }
}

const mapDispatchToProps = {
  clearAlert: AlertActions.clear,
  clearAdmin: AdminActions.clear
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  component,
  path,
  location?
};

const AuthRoute = ({component: Component, location, loggedIn, admin, ...rest}: Props) => {
  useEffect(() => {
    rest.clearAlert();
    rest.clearAdmin();
  }, [rest.router]);

  return (
    <Route {...rest} render={props => (
      loggedIn
        ? (admin.resetPassword === 0
          ? (location.pathname !== Routes.CHANGE_PASSWORD_URL
              ? <Redirect to={Routes.CHANGE_PASSWORD_URL}/>
              : <AuthLayout><Component {...props}/></AuthLayout>
          )
          : <Redirect to={Routes.USER_MANAGE_URL}/>
        )
        : <AuthLayout><Component {...props}/></AuthLayout>
    )}/>
  );
}

export default connector(AuthRoute);