import {UserConstants} from "../../constants/user";

export function userSave(state = {}, action) {
  switch (action.type) {
    case UserConstants.SAVE_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case UserConstants.SAVE_SUCCESS:
      return {
        success: true,
        user: action.user
      };
    case UserConstants.SAVE_FAILURE:
      return {
        error: action.error
      };
    case UserConstants.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
