export const UserConstants = {
  DELETE_REQUEST: 'USER_DELETE_REQUEST',
  DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  DELETE_FAILURE: 'USER_DELETE_FAILURE',

  SEARCH_REQUEST: 'USER_SEARCH_REQUEST',
  SEARCH_SUCCESS: 'USER_SEARCH_SUCCESS',
  SEARCH_FAILURE: 'USER_SEARCH_FAILURE',

  GET_REQUEST: 'USER_GET_REQUEST',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  GET_FAILURE: 'USER_GET_FAILURE',

  GET_BY_EMAIL_REQUEST: 'USER_GET_BY_EMAIL_REQUEST',
  GET_BY_EMAIL_SUCCESS: 'USER_GET_BY_EMAIL_SUCCESS',
  GET_BY_EMAIL_FAILURE: 'USER_GET_BY_EMAIL_FAILURE',

  RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',

  GET_BY_COMPANY_REQUEST: 'USER_GET_BY_COMPANY_REQUEST',
  GET_BY_COMPANY_SUCCESS: 'USER_GET_BY_COMPANY_SUCCESS',
  GET_BY_COMPANY_FAILURE: 'USER_GET_BY_COMPANY_FAILURE',

  ROLE_REQUEST: 'USER_ROLE_REQUEST',
  ROLE_SUCCESS: 'USER_ROLE_SUCCESS',
  ROLE_FAILURE: 'USER_ROLE_FAILURE',

  SAVE_REQUEST: 'USER_SAVE_REQUEST',
  SAVE_SUCCESS: 'USER_SAVE_SUCCESS',
  SAVE_FAILURE: 'USER_SAVE_FAILURE',

  CLEAR_ERRORS: 'USER_CLEAR_ERRORS',
  CLEAR_RESULTS: 'USER_CLEAR_RESULTS',
  CLEAR_FILTERS: 'USER_CLEAR_FILTERS',

  AGGENTS_GET_BY_USER_REQUEST: 'AGGENTS_GET_BY_USER_REQUEST',
  AGGENTS_GET_BY_USER_SUCCESS: 'AGGENTS_GET_BY_USER_SUCCESS',
  AGGENTS_GET_BY_USER_FAILURE: 'AGGENTS_GET_BY_USER_FAILURE',

  GET_LIST_AGENT_REQUEST: 'GET_LIST_AGENT_REQUEST',
  GET_LIST_AGENT_SUCCESS: 'GET_LIST_AGENT_SUCCESS',
  GET_LIST_AGENT_FAILURE: 'GET_LIST_AGENT_FAILURE',
  GET_LIST_AGENT_CLEAR_ERRORS: 'GET_LIST_AGENT_CLEAR_ERRORS',
  GET_LIST_AGENT_CLEAR_RESULTS: 'GET_LIST_AGENT_CLEAR_RESULTS',
};
