import {UserConstants} from "../../constants/user";

export function userGet(state = {user: {}}, action) {
  switch (action.type) {
    case UserConstants.GET_REQUEST:
      return {
        loading: true,
        id: action.id
      };
    case UserConstants.GET_SUCCESS:
      return {
        success: true,
        user: action.user
      };
    case UserConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case UserConstants.CLEAR_ERRORS:
      return {
        user: state.user
      };
    case UserConstants.CLEAR_RESULTS:
      return {};
    default:
      return state;
  }
}
