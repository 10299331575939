import {UserConstants} from "../../constants/user";

export function userResetPasswordEmails(state = {}, action) {
  switch (action.type) {
    case UserConstants.RESET_PASSWORD_REQUEST:
      return {
        loading: true,
        userIds: action.userIds
      };
    case UserConstants.RESET_PASSWORD_SUCCESS:
      return {
        success: true
      };
    case UserConstants.RESET_PASSWORD_FAILURE:
      return {
        error: action.error
      };
    case UserConstants.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
