import React from "react";
import {Row, Col} from 'antd';

import logo from '../assets/jmango-logo-white.png';
import '../animations/pop-up.scss';
import './AuthLayout.scss';

const AuthLayout = (props) => {
  return (
    <Row align={'middle'} justify={'center'} className="auth-layout">
      <Col sm={12}>
        <div className="logo">
          <img src={logo} alt={'JMango360'} width={140}/>
        </div>
        <div className={"form-wrapper"}>
          {props.children}
        </div>
      </Col>
    </Row>
  )
}

export default AuthLayout;
