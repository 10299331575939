import {UserConstants} from "../../constants/user";

export function userRole(state = {roles: []}, action) {
  switch (action.type) {
    case UserConstants.ROLE_REQUEST:
      return {
        loading: true,
        roles: []
      };
    case UserConstants.ROLE_SUCCESS:
      return {
        roles: action.roles,
        complete: true
      };
    case UserConstants.ROLE_FAILURE:
      return {
        error: action.error,
        roles: [],
        complete: true
      };
    case UserConstants.CLEAR_ERRORS:
      return {
        roles: state.roles
      };
    default:
      return state;
  }
}
