import React, {Component} from "react";
import {Form, Input, Button, Alert, Typography} from "antd";
import {CSSTransition} from "react-transition-group";
import {connect, ConnectedProps} from "react-redux";

import {AdminActions} from "../actions/admin";
import {AlertActions} from "../actions/alert";
import {history} from "../helpers/store";
import * as Routes from "../constants/route";

const {Title, Paragraph} = Typography;

const passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/);

function mapStateToProps(state) {
  return {
    alert: state.alert,
    loading: state.adminChangePassword.loading,
    kpData: state.adminLogin.kpData
  }
}

const mapDispatchToProps = {
  changePassword: AdminActions.changePassword,
  logout: AdminActions.logout,
  clearErrors: AlertActions.clear
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

class FormChangePassword extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.onFinish = this.onFinish.bind(this);
  }

  onFinish(values) {
    this.props.clearErrors();
    const {kpData} = this.props;
    this.props.changePassword(kpData, values);
  }

  render() {
    const {alert, loading} = this.props;
    const alertShow = !!(alert && alert.message);

    return (
      <Form
        layout={'vertical'}
        hideRequiredMark={true}
        onFinish={this.onFinish}
        name={'form-change-password'}
      >
        <Title level={3}>Update your password?</Title>

        <CSSTransition
          in={alertShow}
          exit={false}
          timeout={300}
          unmountOnExit
          classNames={'pop-up'}
        >
          <Alert
            message={alert.message}
            type={alert.type}
            closable={true}
            showIcon={true}
          />
        </CSSTransition>

        <Paragraph>
          For security reason, you are asked to change password for the first time login.
        </Paragraph>

        <Paragraph>
          Password must be at least 7 characters and contain both alphabetic and numeric characters.
        </Paragraph>

        <Form.Item
          label={'New password:'}
          name={'password'}
          rules={[
            {required: true, message: 'Please input new password!'},
            {
              pattern: passwordRegex,
              message: 'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'
            }
          ]}
        >
          <Input.Password disabled={loading}/>
        </Form.Item>

        <Form.Item
          label={'Confirm new password:'}
          name={'confirmPassword'}
          dependencies={['password']}
          rules={[
            {required: true, message: 'Please confirm new password!'},
            ({getFieldValue}) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Confirm password does not match entered password!');
              }
            })
          ]}
        >
          <Input.Password disabled={loading}/>
        </Form.Item>

        <Form.Item className={'form-buttons'}>
          <Button
            className={'forgot-password-link'}
            type={'link'}
            size={'large'}
            onClick={() => {
              this.props.logout();
              history.push(Routes.LOGIN_URL)
            }}
            disabled={loading}
          >Back to login</Button>

          <Button
            className={'submit-btn'}
            type={'primary'}
            size={'large'}
            htmlType={'submit'}
            loading={loading}
          >{loading ? '' : 'Submit'}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default connector(FormChangePassword);