export const SettingConstants = {
  GET_REQUEST: 'SETTING_GET_REQUEST',
  GET_SUCCESS: 'SETTING_GET_SUCCESS',
  GET_FAILURE: 'SETTING_GET_FAILURE',

  SAVE_REQUEST: 'SETTING_SAVE_REQUEST',
  SAVE_SUCCESS: 'SETTING_SAVE_SUCCESS',
  SAVE_FAILURE: 'SETTING_SAVE_FAILURE',

  CLEAR: 'SETTING_CLEAR'
};