import {StoreConstants} from "../../constants/store";

export function store(state = {stores: <any>[], pagination: {pageNumber: 1, pageSize: 24}}, action) {
  switch (action.type) {
    case StoreConstants.GET_REQUEST:
      return {
        loading: true,
        stores: state.stores,
        pagination: state.pagination
      };
    case StoreConstants.GET_SUCCESS:
      return {
        stores: action.result.stores.sort((a: any, b: any) => b.defaultStore - a.defaultStore),
        pagination: action.result.pagination
      };
    case StoreConstants.GET_FAILURE:
      return {
        error: action.error,
        stores: [],
        pagination: state.pagination
      };
    case StoreConstants.CLEAR_ERRORS:
      return {
        stores: state.stores,
        pagination: state.pagination
      };
    case StoreConstants.DELETE_SUCCESS:
      return {
        ...state,
        stores: state.stores.filter((store: any) => store.id !== action.result.store.id)
      };
    case StoreConstants.EDIT_SUCCESS:
      return {
        ...state,
        stores: state.stores.map((store: any) => {
          if (store.id === action.result.store.id) {
            return {...store, ...action.result.store};
          }
          return store;
        })
      };
    /*case StoreConstants.ADD_SUCCESS:
      return {
        ...state,
        stores: [...state.stores, ...[action.result.store]]
      };*/
    case StoreConstants.DISABLE_SUCCESS:
      return {
        ...state,
        stores: state.stores.map((store: any) => {
          if (store.id === action.result.store.id) {
            return {...store, enable: 0, busy: false};
          }
          return store;
        })
      };
    case StoreConstants.ENABLE_SUCCESS:
      return {
        ...state,
        stores: state.stores.map((store: any) => {
          if (store.id === action.result.store.id) {
            return {...store, enable: 1, busy: false};
          }
          return store;
        })
      };
    case StoreConstants.ENABLE_REQUEST:
    case StoreConstants.DISABLE_REQUEST:
      return {
        ...state,
        stores: state.stores.map((store: any) => {
          if (store.id === action.store.id) {
            return {...store, busy: true};
          }
          return store;
        })
      }
    default:
      return state;
  }
}
