import {AdminConstants} from "../../constants/admin";

export function adminEditProfile(state = {}, action) {
  switch (action.type) {
    case AdminConstants.EDIT_PROFILE_REQUEST:
      return {
        sending: true,
        admin: action.admin
      };
    case AdminConstants.EDIT_PROFILE_SUCCESS:
      return {
        success: true
      };
    case AdminConstants.EDIT_PROFILE_FAILURE:
      return {};
    case AdminConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
