import {SettingConstants} from "../../constants/setting";

export function settingsSave(state = {}, action) {
  switch (action.type) {
    case SettingConstants.SAVE_REQUEST:
      return {
        loading: true
      };
    case SettingConstants.SAVE_SUCCESS:
      return {
        success: true,
        settings: action.settings
      };
    case SettingConstants.SAVE_FAILURE:
      return {
        error: action.error
      };
    case SettingConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
