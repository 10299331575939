import {CountryConstants} from "../../constants/country";

export function country(state = {data: []}, action) {
  switch (action.type) {
    case CountryConstants.GET_REQUEST:
      return {
        loading: true
      };
    case CountryConstants.GET_SUCCESS:
      return {
        data: action.data
      };
    case CountryConstants.GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}