import {StoreConstants} from "../../constants/store";

export function storeDelete(state = {}, action) {
  switch (action.type) {
    case StoreConstants.DELETE_REQUEST:
      return {
        loading: true,
        store: action.store
      };
    case StoreConstants.DELETE_SUCCESS:
      return {
        success: true,
        store: action.store
      };
    case StoreConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    case StoreConstants.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
