import {UserConstants} from "../../constants/user";
import {AdminConstants} from "../../constants/admin";

export function userSearch(state = {users: [], pagination: {}, filters: {}, sorter: {}}, action) {
  switch (action.type) {
    case UserConstants.SEARCH_REQUEST:
      return {
        loading: true,
        params: action.params,
        users: state.users,
        pagination: state.pagination,
        filters: state.filters,
        sorter: state.sorter
      };
    case UserConstants.SEARCH_SUCCESS:
      return {
        success: true,
        // users: action.result.list,
        //   users: action.result.isManageTable ? action.result.list : (action.result.pagination.pageNumber === 1 ? action.result.list : [...state.users, ...action.result.list]),
          users: action.result.list,
        pagination: action.result.pagination,
        filters: action.result.filters,
        sorter: action.result.sorter,
          full: action.result.list.length === 0
      };
    case UserConstants.SEARCH_FAILURE:
      return {
        error: action.error,
        users: [],
        pagination: state.pagination,
        filters: state.filters,
        sorter: state.sorter
      };
    case UserConstants.CLEAR_ERRORS:
      return {
        users: state.users,
        pagination: state.pagination,
        filters: state.filters,
        sorter: state.sorter
      };
    case AdminConstants.LOGOUT:
    case UserConstants.CLEAR_RESULTS:
      return {
        users: [],
        pagination: state.pagination,
        filters: {},
        sorter: state.sorter
      }
    case UserConstants.DELETE_SUCCESS:
      return {
        users: state.users.filter((user: any) => action.userIds.indexOf(user.id) === -1),
        pagination: {...state.pagination, total: state.pagination['total'] - 1},
        filters: state.filters,
        sorter: state.sorter
      }
    case UserConstants.CLEAR_FILTERS:
      return {
        users: state.users,
        pagination: state.pagination,
        filters: {},
        sorter: state.sorter
      }
    default:
      return state;
  }
}
