import React, {lazy} from "react";
import {Router, Route, Switch, Redirect} from "react-router-dom";

import "./global.scss";
import {history} from "./helpers/store";
import AdminRoute from "./routes/AdminRoute";
import AuthRoute from "./routes/AuthRoute";
import * as Routes from "./constants/route";
import LoginPage from "./pages/auth/login";
import ResetPasswordPage from "./pages/auth/resetPassword";
import ForgotPasswordPage from "./pages/auth/forgotPassword";
import ChangePasswordPage from "./pages/auth/changePassword";

const Dashboard = lazy(() => import("./pages/dashboard"));
const AdminChangePassword = lazy(() => import( "./pages/admin/changePassword"));
const AdminView = lazy(() => import( "./pages/admin/view"));
const AdminEdit = lazy(() => import( "./pages/admin/edit"));
const UserManage = lazy(() => import( "./pages/user"));
const UserEdit = lazy(() => import( "./pages/user/edit"));
const UserCreate = lazy(() => import( "./pages/user/create"));
const UserView = lazy(() => import( "./pages/user/view"));
const CompanyManage = lazy(() => import( "./pages/company"));
const CompanyEdit = lazy(() => import( "./pages/company/edit"));
const CompanyCreate = lazy(() => import( "./pages/company/create"));
const CompanyView = lazy(() => import( "./pages/company/view"));
const Settings = lazy(() => import( "./pages/settings"));

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={'/'}>
          <Redirect to={'/login'}/>
        </Route>

        <AuthRoute path={Routes.LOGIN_URL} component={LoginPage}/>
        <AuthRoute path={Routes.RESET_PASSWORD_URL} component={ResetPasswordPage}/>
        <AuthRoute path={Routes.CHANGE_FORGOT_PASSWORD_URL} component={ForgotPasswordPage}/>
        <AuthRoute path={Routes.CHANGE_PASSWORD_URL} component={ChangePasswordPage}/>

        <AdminRoute path={Routes.DASHBOARD_URL} component={Dashboard}/>

        <AdminRoute path={Routes.ADMIN_CHANGE_PASSWORD_URL} component={AdminChangePassword}/>
        <AdminRoute path={Routes.ADMIN_PROFILE_URL} component={AdminView}/>
        <AdminRoute path={Routes.ADMIN_EDIT_PROFILE_URL} component={AdminEdit}/>

        <AdminRoute path={Routes.USER_MANAGE_URL} component={UserManage}/>
        <AdminRoute path={Routes.USER_EDIT_URL} component={UserEdit}/>
        <AdminRoute path={Routes.USER_CREATE_URL} component={UserCreate}/>
        <AdminRoute path={Routes.USER_VIEW_URL} component={UserView}/>

        <AdminRoute path={Routes.COMPANY_MANAGE_URL} component={CompanyManage}/>
        <AdminRoute path={Routes.COMPANY_EDIT_URL} component={CompanyEdit}/>
        <AdminRoute path={Routes.COMPANY_CREATE_URL} component={CompanyCreate}/>
        <AdminRoute path={Routes.COMPANY_VIEW_URL} component={CompanyView}/>

        <AdminRoute path={Routes.SETTINGS_URL} component={Settings}/>

        <Redirect from={'*'} to={'/'}/>
      </Switch>
    </Router>
  )
}

export default App;