export const ImportConstants = {
  IMPORT_REQUEST: 'IMPORT_REQUEST',
  IMPORT_SUCCESS: 'IMPORT_SUCCESS',
  IMPORT_FAILURE: 'IMPORT_FAILURE',

  CHECK_REQUEST: 'CHECK_REQUEST',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAILURE: 'CHECK_FAILURE',

  CLEAR: 'IMPORT_CLEAR'
};