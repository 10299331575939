import _ from "lodash";
import {AddressConstants} from "../../constants/address";

export function addressSave(state = {addresses: []}, action) {
  switch (action.type) {
    case AddressConstants.SAVE_REQUEST:
      return {
        loading: true,
        address: action.address,
        addresses: state.addresses
      };
    case AddressConstants.SAVE_SUCCESS:
      return {
        success: true,
        address: action.address,
        addresses: _.sortBy(_.uniqBy([...[action.address], ...state.addresses], 'id'), 'id')
      };
    case AddressConstants.SET_ADDRESS:
      return {
        addresses: _.sortBy(action.addresses, 'id')
      };
    case AddressConstants.DELETE_SUCCESS:
      return {
        addresses: state.addresses.filter((a: any) => a.id !== action.addressId)
      }
    case AddressConstants.SAVE_FAILURE:
      return {
        error: action.error,
        addresses: state.addresses
      };
    case AddressConstants.CLEAR_ERRORS:
      return {
        addresses: state.addresses
      };
    case AddressConstants.CLEAR_RESULTS:
      return {
        addresses: []
      };
    default:
      return state;
  }
}
