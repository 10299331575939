import {AddressConstants} from "../constants/address";
import {AddressServices} from "../services/address";

export const AddressActions = {
  save,
  del,
  clearErrors,
  clearResults,
    addAddressShipping,
    delAddressShipping,
    editAddressShipping,
    setAddressLocal,
    saveBillingAddress,
    clearResultAddressLocal,
    clearErrorsAddressLocal
};

function save(address) {
  return dispatch => {
    dispatch(request({address}));

    AddressServices.save(address)
      .then(
        address => {
          dispatch(success(address));
        },
        error => {
          dispatch(failure(error));
        });
  };

  function request(address) {
    return {type: AddressConstants.SAVE_REQUEST, address}
  }

  function success(address) {
    return {type: AddressConstants.SAVE_SUCCESS, address}
  }

  function failure(error) {
    return {type: AddressConstants.SAVE_FAILURE, error}
  }
}

function del(addressId, index) {
  return dispatch => {
    dispatch(request({addressId}, index));

    AddressServices.del(addressId)
      .then(
        addressId => {
          dispatch(success(addressId));
        },
        error => {
          dispatch(failure(error));
        });
  };

  function request(addressId, index) {
    return {type: AddressConstants.DELETE_REQUEST, addressId, index}
  }

  function success(addressId) {
    return {type: AddressConstants.DELETE_SUCCESS, addressId}
  }

  function failure(error) {
    return {type: AddressConstants.DELETE_FAILURE, error}
  }
}

function clearErrors() {
  return {type: AddressConstants.CLEAR_ERRORS}
}

function clearResults() {
  return {type: AddressConstants.CLEAR_RESULTS}
}


function addAddressShipping(address) {
    return {type: AddressConstants.ADD_SHIPPING_ADDRESS_LOCAL, address}
}
function delAddressShipping(address, index) {
    return {type: AddressConstants.DEL_SHIPPING_ADDRESS_LOCAL, address, index}
}
function editAddressShipping(address, index) {
    return {type: AddressConstants.EDIT_SHIPPING_ADDRESS_LOCAL, address, index}
}
function setAddressLocal(billingAddress, shippingAddresses) {
    return {type: AddressConstants.SET_ADDRESS_LOCAL, billingAddress, shippingAddresses}
}
function saveBillingAddress(billingAddress) {
    return {type: AddressConstants.SAVE_BILIING_ADDRESS_LOCAL, billingAddress}
}
function clearResultAddressLocal() {
    return {type: AddressConstants.CLEAR_RESULTS_SHIPPING_ADDRESS_LOCAL}
}
function clearErrorsAddressLocal() {
    return {type: AddressConstants.CLEAR_ERRORS_ADDRESS_LOCAL}
}
