import {UserConstants} from "../constants/user";
import {UserServices} from "../services/user";
import {AlertActions} from "./alert";

export const UserActions = {
  deleteUsers,
  searchUsers,
  getRoles,
  clearErrors,
  clearResults,
  clearFilters,
  saveUser,
  getUserById,
  getUserByEmail,
  getUsersByCompany,
  getAggentsByUser,
  getListAgent,
  clearListAgent,
  resetPasswordEmails
};

function resetPasswordEmails(userIds) {
  return dispatch => {
    dispatch(request(userIds));

    UserServices.resetPasswordEmails(userIds)
      .then(
        () => {
          dispatch(success(userIds));
        },
        error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
  }

  function request(userIds) {
    return {type: UserConstants.RESET_PASSWORD_REQUEST, userIds};
  }

  function success(userIds) {
    return {type: UserConstants.RESET_PASSWORD_SUCCESS, userIds};
  }

  function failure(error) {
    return {type: UserConstants.RESET_PASSWORD_FAILURE, error};
  }
}

function deleteUsers(userIds) {
  return dispatch => {
    dispatch(request(userIds));

    UserServices.deleteUsers(userIds)
      .then(
        () => {
          dispatch(success(userIds));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(userIds) {
    return {type: UserConstants.DELETE_REQUEST, userIds};
  }

  function success(userIds) {
    return {type: UserConstants.DELETE_SUCCESS, userIds};
  }

  function failure(error) {
    return {type: UserConstants.DELETE_FAILURE, error};
  }
}

function searchUsers(params) {
  return dispatch => {
    dispatch(request(params));

    UserServices.searchUsers(params)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(params) {
    return {type: UserConstants.SEARCH_REQUEST, params};
  }

  function success(result) {
    return {type: UserConstants.SEARCH_SUCCESS, result};
  }

  function failure(error) {
    return {type: UserConstants.SEARCH_FAILURE, error};
  }
}

function clearErrors() {
  return {
    type: UserConstants.CLEAR_ERRORS
  }
}

function clearResults() {
  return {
    type: UserConstants.CLEAR_RESULTS
  }
}

function getRoles() {
  return dispatch => {
    dispatch(request());

    UserServices.getRoles()
      .then(
        roles => {
          dispatch(success(roles));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request() {
    return {type: UserConstants.ROLE_REQUEST};
  }

  function success(roles) {
    return {type: UserConstants.ROLE_SUCCESS, roles};
  }

  function failure(error) {
    return {type: UserConstants.ROLE_FAILURE, error};
  }
}

function saveUser(user, kpData) {
  return dispatch => {
    dispatch(request(user));

    UserServices.saveUser(user, kpData)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
  }

  function request(user) {
    return {type: UserConstants.SAVE_REQUEST, user}
  }

  function success(user) {
    return {type: UserConstants.SAVE_SUCCESS, user}
  }

  function failure(error) {
    return {type: UserConstants.SAVE_FAILURE, error}
  }
}

function getUserById(id) {
  return dispatch => {
    dispatch(request(id));

    UserServices.getUserById(id)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(id) {
    return {type: UserConstants.GET_REQUEST, id};
  }

  function success(user) {
    return {type: UserConstants.GET_SUCCESS, user};
  }

  function failure(error) {
    return {type: UserConstants.GET_FAILURE, error};
  }
}

function getUserByEmail(email) {
  return dispatch => {
    dispatch(request(email));

    UserServices.getUserByEmail(email)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        });
  }

  function request(email) {
    return {type: UserConstants.GET_BY_EMAIL_REQUEST, email};
  }

  function success(user) {
    return {type: UserConstants.GET_BY_EMAIL_SUCCESS, user};
  }

  function failure(error) {
    return {type: UserConstants.GET_BY_EMAIL_FAILURE, error};
  }
}

function getUsersByCompany(companyId) {
  return dispatch => {
    dispatch(request(companyId));

    UserServices.getUsersByCompany(companyId)
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          dispatch(failure(error));
        });
  }

  function request(companyId) {
    return {type: UserConstants.GET_BY_COMPANY_REQUEST, companyId};
  }

  function success(users) {
    return {type: UserConstants.GET_BY_COMPANY_SUCCESS, users};
  }

  function failure(error) {
    return {type: UserConstants.GET_BY_COMPANY_FAILURE, error};
  }
}


function getAggentsByUser(userId) {
  return dispatch => {
    dispatch(request(userId));

    UserServices.getAgentsByUser(userId)
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          dispatch(failure(error));
        });
  }

  function request(userId) {
    return {type: UserConstants.AGGENTS_GET_BY_USER_REQUEST, userId};
  }

  function success(users) {
    return {type: UserConstants.AGGENTS_GET_BY_USER_SUCCESS, users};
  }

  function failure(error) {
    return {type: UserConstants.AGGENTS_GET_BY_USER_FAILURE, error};
  }
}

function getListAgent(params) {
  return dispatch => {
    dispatch(request(params));

    UserServices.getListAgent(params)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(params) {
    return {type: UserConstants.GET_LIST_AGENT_REQUEST, params};
  }

  function success(result) {
    return {type: UserConstants.GET_LIST_AGENT_SUCCESS, result};
  }

  function failure(error) {
    return {type: UserConstants.GET_LIST_AGENT_FAILURE, error};
  }
}

function clearListAgent() {
  return {type: UserConstants.GET_LIST_AGENT_CLEAR_RESULTS};
}


function clearFilters() {
  return {type: UserConstants.CLEAR_FILTERS};
}
