import {combineReducers} from "redux";
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from "redux-first-history";

import {alert} from "./alert";
import {adminLogin} from "./admin/login";
import {adminResetPassword} from "./admin/resetPassword";
import {adminChangePassword} from "./admin/changePassword";
import {adminChangePasswordForgot} from "./admin/changePasswordForgot";
import {adminEditProfile} from "./admin/editProfile";
import {userSearch} from "./user/search";
import {userDelete} from "./user/delete";
import {userRole} from "./user/role";
import {userSave} from "./user/save";
import {userGet} from "./user/get";
import {userGetByEmail} from "./user/getByEmail";
import {userGetByCompany} from "./user/getByCompany";
import {agentsGetByUser} from "./user/agentsGetByUser";
import {userResetPasswordEmails} from "./user/resetPasswordEmails";
import {listAgent} from "./user/getListAgent";
import {companySearch} from "./company/search";
import {companyDelete} from "./company/delete";
import {companySave} from "./company/save";
import {companyByUser} from "./company/byUser";
import {companyById} from "./company/byId";
import {settingsLoader} from "./settings/loader";
import {settingsSave} from "./settings/save";
import {importRunner} from "./import/runner";
import {importStatus} from "./import/status";
import {country} from "./country/get";
import {countryState} from "./country/state";
import {customerGroup} from "./group";
import {addressSave} from "./address/save";
// import {addressDelete} from "./address/delete";
import {store} from "./store/get";
import {storeLanguage} from "./store/language";
import {storeAdd} from "./store/add";
import {storeEnable} from "./store/enable";
import {storeDisable} from "./store/disable";
import {storeDelete} from "./store/delete";
import {storeEdit} from "./store/edit";
import {addressLocal} from "./address/addressLocal";

const {createReduxHistory, routerReducer, routerMiddleware} = createReduxHistoryContext({
  history: createBrowserHistory()
});

const rootReducer = combineReducers({
  router: routerReducer,

  alert,
  importRunner,
  importStatus,
  customerGroup,

  addressSave,
  // addressDelete,
  addressLocal,

  country,
  countryState,

  adminLogin,
  adminResetPassword,
  adminChangePassword,
  adminChangePasswordForgot,
  adminEditProfile,

  userSearch,
  userDelete,
  userRole,
  userSave,
  userGet,
  userGetByEmail,
  userGetByCompany,
  agentsGetByUser,
  listAgent,
  userResetPasswordEmails,

  companySearch,
  companyDelete,
  companySave,
  companyByUser,
  companyById,

  settingsLoader,
  settingsSave,

  store,
  storeLanguage,
  storeAdd,
  storeEnable,
  storeDisable,
  storeDelete,
  storeEdit,
});

export {rootReducer, createReduxHistory, routerMiddleware};
