import {GroupConstants} from "../constants/group";

export function customerGroup(state = {data: [], pagination: {pageNumber: 1, pageSize: 100}}, action) {
  switch (action.type) {
    case GroupConstants.GET_REQUEST:
      return {
        loading: true,
        data: state.data,
        pagination: state.pagination
      };
    case GroupConstants.GET_SUCCESS:
      return {
        data: action.result.pagination.pageNumber === 1 ? action.result.list : [...state.data, ...action.result.list],
        pagination: action.result.pagination,
        full: action.result.list.length === 0
      };
    case GroupConstants.GET_FAILURE:
      return {
        error: action.error,
        data: [],
        pagination: state.pagination
      };
    default:
      return state;
  }
}
