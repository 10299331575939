import React, {Component} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Result} from "antd";
import {CheckCircleOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";

import FormResetPassword from "../../components/FormResetPassword";
import * as Routes from "../../constants/route";
import "./resetPassword.scss";
import {Tween} from "react-gsap";
import {popupAnimation} from "../../constants/animation";

function mapStateToProps(state) {
  return {
    success: state.adminResetPassword.success,
    email: state.adminResetPassword.email
  }
}

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

class ResetPasswordPage extends Component<Props, any> {
  render() {
    const {success, email} = this.props;

    return (
      success
        ? <Tween from={popupAnimation} duration={0.4}>
          <div>
            <Result
              status="success"
              icon={<CheckCircleOutlined/>}
              title=""
              subTitle={<>An email with a link to reset your passord has been sent to <b>{email}</b></>}
              className={"reset-password-success"}
              extra={[
                <Link to={Routes.LOGIN_URL} key={'login'}>
                  <Button type="primary" size={'large'}>
                    Back to login
                  </Button>
                </Link>
              ]}
            />
          </div>
        </Tween>
        : <FormResetPassword/>
    )
  }
}

export default connector(ResetPasswordPage);
