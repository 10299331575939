export const pageAnimation = {
  opacity: 0,
  y: 50,
  ease: 'Power4'
}

export const popupAnimation = {
  opacity: 0,
  scale: 0.9,
  ease: 'Power4'
}