import {SettingConstants} from "../constants/setting";
import {SettingServices} from "../services/setting";

export const SettingActions = {
  getSettings,
  saveSettings
}

function getSettings() {
  return dispatch => {
    dispatch(request());

    SettingServices.getSettings()
      .then(
        settings => {
          dispatch(success(settings));
        },
        error => {
          dispatch(failure(error));
        });
  }

  function request() {
    return {type: SettingConstants.GET_REQUEST}
  }

  function success(settings) {
    return {type: SettingConstants.GET_SUCCESS, settings}
  }

  function failure(error) {
    return {type: SettingConstants.GET_FAILURE, error}
  }
}

function saveSettings(values: any = {}) {
  return dispatch => {
    dispatch(request());

    SettingServices.saveSettings(values)
      .then(
        (settings) => {
          dispatch(success(settings));
          dispatch(save(settings));
        },
        error => {
          dispatch(failure(error));
        });
  }

  function request() {
    return {type: SettingConstants.SAVE_REQUEST}
  }

  function success(settings) {
    return {type: SettingConstants.SAVE_SUCCESS, settings}
  }

  function save(settings) {
    return {type: SettingConstants.GET_SUCCESS, settings}
  }

  function failure(error) {
    return {type: SettingConstants.SAVE_FAILURE, error}
  }
}