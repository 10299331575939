import React, {Component} from "react";
import {Form, Input, Button, Alert, Typography} from "antd";
import {CSSTransition} from "react-transition-group";
import {connect, ConnectedProps} from "react-redux";

import {history} from "../helpers/store";
import {AdminActions} from "../actions/admin";
import * as Routes from "../constants/route";
import {Tween} from "react-gsap";
import {pageAnimation} from "../constants/animation";
import {AlertActions} from "../actions/alert";
import {emailRegex} from "../helpers";

const {Title, Paragraph} = Typography;

function mapStateToProps(state) {
  return {
    alert: state.alert,
    loading: state.adminResetPassword.requesting
  }
}

const mapDispatchToProps = {
  resetPassword: AdminActions.resetPassword,
  clearErrors: AlertActions.clear
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

class FormResetPassword extends Component<Props, {}> {
  private formRef: React.RefObject<any>;

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormBlur = this.onFormBlur.bind(this);
  }

  onFormSubmit(values) {
    this.props.clearErrors();
    this.props.resetPassword(values.email.trim());
  }

  onFormBlur(e) {
    if (e.target.value) {
      this.formRef.current.setFieldsValue({email: e.target.value.trim()});
    }
  }

  render() {
    const {alert, loading} = this.props;
    const alertShow = !!(alert && alert.message);

    return (
      <Tween staggerFrom={pageAnimation} stagger={0.2} duration={0.4}>
        <div>
          <Title level={3}>Forgot your password?</Title>
        </div>

        <div>
          <Form
            ref={this.formRef}
            layout={'vertical'}
            hideRequiredMark={true}
            onFinish={this.onFormSubmit}
            name={'form-reset-password'}
          >
            <CSSTransition
              in={alertShow}
              exit={false}
              timeout={300}
              unmountOnExit
              classNames={'pop-up'}
            >
              <Alert
                message={alert.message}
                type={alert.type}
                closable={true}
                showIcon={true}
              />
            </CSSTransition>

            <Paragraph style={{marginBottom: 20, marginTop: 30}}>
              Please enter your email address here.<br/>
              We will send you an email with instruction shortly.
            </Paragraph>

            <Form.Item
              label={'Email Address:'}
              name={'email'}
              rules={[
                {required: true, message: 'Please input your email address!'},
                {
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (!value.trim().match(emailRegex) || value.length > 255) {
                      return Promise.reject('Please enter a valid email address!');
                    }

                    return Promise.resolve();
                  }
                }
              ]}
              style={{marginBottom: 20}}
            >
              <Input disabled={loading} onBlur={this.onFormBlur}/>
            </Form.Item>

            <Form.Item className={'form-buttons'}>
              <Button
                className={'forgot-password-link'}
                type={'link'}
                size={'large'}
                onClick={() => {
                  history.push(Routes.LOGIN_URL)
                }}
                disabled={loading}
              >Back to login</Button>

              <Button
                className={'submit-btn'}
                type={'primary'}
                size={'large'}
                htmlType={'submit'}
                loading={loading}
              >{loading ? '' : 'Submit'}</Button>
            </Form.Item>
          </Form>
        </div>
      </Tween>
    )
  }
}

export default connector(FormResetPassword);