import {AlertConstants} from "../constants/alert";

export const AlertActions = {
  error,
  success,
  clear
};

function error(message) {
  return {
    type: AlertConstants.ERROR,
    message
  }
}

function success(message) {
  return {
    type: AlertConstants.SUCCESS,
    message
  }
}

function clear() {
  return {
    type: AlertConstants.CLEAR
  }
}