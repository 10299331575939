import React, {Suspense, useEffect} from "react";
import {Layout, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {connect, ConnectedProps} from "react-redux";
import {Link} from "react-router-dom";

import logo from "../assets/jmango-logo-white.png";
import * as Routes from "../constants/route";
import AdminProfilePopover from "../components/AdminProfilePopover";
import MenuSider from "../components/MenuSider";
import LoadingIndicator from "../components/LoadingIndicator";
import {SettingActions} from "../actions/settings";
import '../animations/pop-up.scss';
import "./AdminLayout.scss";
import {UserActions} from "../actions/user";

const {Header, Sider, Content} = Layout;

function mapStateToProps(state, props) {
  return {
    loading:
      state.userDelete.loading ||
      state.userSearch.loading ||
      state.companyDelete.loading ||
      (state.companySearch.loading && props.location.pathname === Routes.COMPANY_MANAGE_URL) ||
      state.settingsSave.loading ||
      state.userGet.loading ||
      state.userSave.loading ||
      state.companyById.loading ||
      state.companySave.loading,
    settings: state.settingsLoader.settings || {},
    settingsComplete: state.settingsLoader.complete,
    roles: state.userRole.roles || [],
    rolesComplete: state.userRole.complete
  }
}

const mapDispatchToProps = {
  getSettings: SettingActions.getSettings,
  getRoles: UserActions.getRoles
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  children
};

const AdminLayout = (props: Props) => {
  useEffect(() => {
    if (!Object.keys(props.settings).length) {
      props.getSettings();
    }

    if (!props.roles.length) {
      props.getRoles();
    }
  }, []);

  if (!props.settingsComplete && !props.rolesComplete) {
    return <LoadingIndicator/>
  }

  return (
    <Layout className={'admin-layout'}>
      <Sider width={183} className={'sider-wrapper'}>
        <div className='logo'>
          <Link to={Routes.DASHBOARD_URL}>
            <img src={logo} width={93} alt={'Jmango360'}/>
          </Link>
        </div>
        <MenuSider/>
      </Sider>
      <Layout className={'main-wrapper'} id={'main-wrapper'}>
        <Spin
          spinning={!!props.loading}
          size={'large'}
          indicator={<LoadingOutlined/>}
          wrapperClassName={'page-loading'}
        >
          <Header>
            <AdminProfilePopover/>
          </Header>
          <Content>
            <Suspense fallback={<div><LoadingOutlined/> Loading...</div>}>
              {props.children}
            </Suspense>
          </Content>
        </Spin>
      </Layout>
    </Layout>
  )
}

export default connector(AdminLayout);