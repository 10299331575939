import {CountryConstants} from "../../constants/country";

export function countryState(state = {data: {}}, action) {
  switch (action.type) {
    case CountryConstants.STATE_REQUEST:
      return {
        loading: true,
        data: state.data
      };
    case CountryConstants.STATE_SUCCESS:
      return {
        data: {...state.data, ...action.data}
      };
    case CountryConstants.STATE_FAILURE:
      return {
        error: action.error,
        data: state.data
      };
    default:
      return state;
  }
}