import util from "util";
import _ from "lodash";
import {ApiConstants} from "../constants/api";
import {AppKey, HeaderKey} from "../constants";
import localStore from "../utils/localStorage";


export const CompanyServices = {
  searchCompanies,
  deleteCompanies,
  saveCompany,
  getCompaniesByUser,
  getCompanyById
};

function searchCompanies(params: any = {}) {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    }
  };

  const url = util.format(ApiConstants.COMPANY_SEARCH_URL,
    AppKey,
    params.pageNumber || 1,
    params.pageSize || 100,
    params.name ? params.name.trim() : '',
    params.owner || '',
    params.phone ? params.phone.trim() : '',
    params.address ? params.address.trim() : '',
    params.branchCode ? params.branchCode.trim() : '',
    params.sortBy || '',
    params.sortDirection || ''
  );

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.list) {
            data.isManageTable = params.hasOwnProperty('phone');
            data.filters = {
              name: params.name || '',
              phone: params.phone || '',
              address: params.address || ''
            };
            return Promise.resolve(data);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function deleteCompanies(companyIds = []) {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'companyIds': companyIds
    })
  };

  return fetch(ApiConstants.COMPANY_DELETE_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve();
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function saveCompany(kpData: any, values: any) {
  const requestOptions = {
    method: values.id ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
      [HeaderKey.JM360_KP_VER]: kpData.keypairVersion,
      [HeaderKey.JM360_KP]: kpData.keypairData,
      [HeaderKey.JM360_KP_EXP]: kpData.keyPairExpiration
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'company': values
    })
  };

  const url = values.id ? ApiConstants.COMPANY_EDIT_URL : ApiConstants.COMPANY_CREATE_URL;

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve();
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getCompaniesByUser(userId) {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    }
  };

  const url = util.format(ApiConstants.COMPANY_BY_USER_URL, userId, '', '');

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.list) {
            return Promise.resolve(_.uniqBy(data.list, 'id'));
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getCompanyById(companyId) {
    const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
        ...headerAPI
    }
  };

  const url = util.format(ApiConstants.COMPANY_BY_ID_URL, companyId, AppKey);

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.company) {
            return Promise.resolve(data.company);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}
