import {AdminConstants} from "../../constants/admin";
import {AlertConstants} from "../../constants/alert";

export function adminResetPassword(state = {}, action) {
  switch (action.type) {
    case AdminConstants.RESET_PASSWORD_REQUEST:
      return {
        requesting: true,
        email: action.email
      };
    case AdminConstants.RESET_PASSWORD_SUCCESS:
      return {
        success: true,
        email: action.email
      };
    case AdminConstants.RESET_PASSWORD_FAILURE:
      return {};
    case AdminConstants.CLEAR:
    case AlertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
