export const LOGIN_URL = '/login';
export const RESET_PASSWORD_URL = '/reset-password';
export const CHANGE_FORGOT_PASSWORD_URL = '/forget-password';
export const CHANGE_PASSWORD_URL = '/change-password';

export const DASHBOARD_URL = '/dashboard';

export const ADMIN_CHANGE_PASSWORD_URL = '/admin/change-password';
export const ADMIN_EDIT_PROFILE_URL = '/admin/edit';
export const ADMIN_PROFILE_URL = '/admin/profile';

export const USER_MANAGE_URL = '/user/manage';
export const USER_EDIT_URL = '/user/edit/:id';
export const USER_VIEW_URL = '/user/view/:id';
export const USER_CREATE_URL = '/user/create';

export const COMPANY_MANAGE_URL = '/company/manage';
export const COMPANY_EDIT_URL = '/company/edit/:id';
export const COMPANY_VIEW_URL = '/company/view/:id';
export const COMPANY_CREATE_URL = '/company/create';

export const SETTINGS_URL = '/settings';