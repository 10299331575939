import util from "util";
import {ApiConstants} from "../constants/api";
import {AppKey, HeaderKey} from "../constants";
import localStore from "../utils/localStorage";

export const UserServices = {
  deleteUsers,
  searchUsers,
  getRoles,
  saveUser,
  getUserById,
  getUserByEmail,
  getUsersByCompany,
  getAgentsByUser,
  getListAgent,
  resetPasswordEmails
};

function resetPasswordEmails(userIds = []) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'userIds': userIds
    })
  };

  return fetch(ApiConstants.USER_RESET_PASSWORD_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error?.code) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve();
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function deleteUsers(userIds = []) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'userIds': userIds
    })
  };

  return fetch(ApiConstants.USER_DELETE_URL, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve();
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function searchUsers(params: any = {}) {
  const headerAPI = localStore.getObject('headerAPI', {});

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };
  const url = util.format(ApiConstants.USER_SEARCH_URL,
    params.pageNumber || 1,
    params.pageSize || 20,
    params.email ? params.email.trim() : '',
    params.name ? params.name.trim() : '',
    Array.isArray(params.roleIds) ? params.roleIds.join(',') : '',
    params.agentCode ? params.agentCode.trim() : '',
    params.sortBy || 'id',
    params.sortDirection || 'desc'
  );

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.list) {
            data.filters = {
              name: params.name || '',
              roleIds: Array.isArray(params.roleIds) ? params.roleIds.map(role => parseInt(role)) : [],
              email: params.email || ''
            };
            data.sorter = {
              name: params.sortBy || 'id',
              dir: params.sortDirection === 'asc' ? 'ascend' : 'descend'
            };
            return Promise.resolve(data);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getRoles() {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };

  return fetch(ApiConstants.USER_ROLE_URL, requestOptions)
    .then(response => {
      if (response.status === 200) {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.list) {
            return Promise.resolve(data.list);
          }
        });
      } else {
        return Promise.reject(response.statusText);
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function saveUser(user, kpData) {
  const requestOptions = {
    method: user.id ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json',
      [HeaderKey.JM360_KP_VER]: kpData.keypairVersion,
      [HeaderKey.JM360_KP]: kpData.keypairData,
      [HeaderKey.JM360_KP_EXP]: kpData.keyPairExpiration
    },
    body: JSON.stringify({
      'appKey': AppKey,
      'user': user
    })
  };

  const url = user.id ? ApiConstants.USER_EDIT_URL : ApiConstants.USER_CREATE_URL;

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status === 200) {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve(data.customer);
          }
        });
      } else {
        return Promise.reject(response.statusText);
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getUserById(id: number) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };

  const url = util.format(ApiConstants.USER_BY_ID_URL, id);

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.customer) {
            return Promise.resolve(data.customer);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getUserByEmail(email: string) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };

  const url = util.format(ApiConstants.USER_BY_EMAIL_URL, AppKey, email);

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.customer) {
            return Promise.resolve(data.customer);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getUsersByCompany(companyId: number) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };

  const url = util.format(ApiConstants.USER_BY_COMPANY_URL, companyId);

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve(data.list);
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}


function getAgentsByUser(userId: number) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };

  const url = util.format(ApiConstants.AGGENT_BY_USER_URL, userId);

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else {
            return Promise.resolve(data.list);
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}

function getListAgent(params: any = {}) {
  const headerAPI = localStore.getObject('headerAPI', {});
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headerAPI
    }
  };

  const url = util.format(ApiConstants.AGGENT_SEARCH_URL,
    params.pageNumber || 1,
    params.pageSize || 20,
    params.email ? params.email.trim() : '',
    params.name ? params.name.trim() : '',
    Array.isArray(params.roleIds) ? params.roleIds.join(',') : '',
    params.agentCode ? params.agentCode.trim() : '',
    params.sortBy || 'id',
    params.sortDirection || 'desc'
  );

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.statusText);
      } else {
        return response.json().then(data => {
          if (data.error) {
            return Promise.reject(data.error.message || 'An error occurred, please try again later');
          } else if (data.list) {
            data.filters = {
              name: params.name || '',
              roleIds: Array.isArray(params.roleIds) ? params.roleIds.map(role => parseInt(role)) : [],
              email: params.email || ''
            };
            data.sorter = {
              name: params.sortBy || 'id',
              dir: params.sortDirection === 'asc' ? 'ascend' : 'descend'
            };
            return Promise.resolve(data);
          } else {
            return Promise.reject('An error occurred, please try again later');
          }
        });
      }
    })
    .catch(error => {
      return Promise.reject(error.message || error);
    });
}
