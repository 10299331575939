import React, {useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Modal} from "antd";

import FormLogin from "../../components/FormLogin";
import {AdminActions} from "../../actions/admin";

function mapStateToProps(state) {
  return {
    sessionExpired: state.adminLogin.sessionExpired
  }
}

const mapDispatchToProps = {
  clearExpireSession: AdminActions.clearExpireSession
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const LoginPage = (props: Props) => {
  useEffect(() => {
    if (props.sessionExpired) {
      Modal.info({
        content: 'For security reason, you have been automatically logged out due to inactivity',
        onOk() {
          props.clearExpireSession();
        },
        icon: null,
        className: 'expire-dialog'
      });
    }
  }, [props.sessionExpired]);

  return (
    <FormLogin/>
  )
}

export default connector(LoginPage);
