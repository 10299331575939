export const AppKey = "5e9691867280431259b23d3b"; // appKey Production
export const ADMIN_TIMEOUT = 3600; //seconds
export const RoleConstants = {
  OWNER_TYPE: 1,
  AGENT_TYPE: 2,
  EMPLOYEE_TYPE: 3,
  STAFF_TYPE: 5,
  SUPERAGENT_TYPE: 7,
};

export const AppLevel = {
  DEVELOPMENT: 1,
  PREVIEW: 2,
  BRANDED: 3,
};

export const AppType = {
  MAGENTO: "MAGENTO",
  JMANGO: "JMANGO",
  QUOTE: "QUOTE",
  LIGHT_SPEED: "LIGHT_SPEED",
};

export const AppCode = {
  APP_TYPE_MAGENTO: 1,
  APP_TYPE_JMANGO360: 0,
  APP_TYPE_BARBERBOOKING: 2,
  APP_TYPE_SEOSHOP: 3,
  APP_TYPE_PRESTASHOP: 5,
  APP_TYPE_BIGCOM: 7,
  APP_TYPE_PTS_PWA: 8,
};

export const AppTypeCode = {
  MAGENTO_CODE: "mgt",
  JMANGO360_CODE: "std",
  SEOSHOP_CODE: "lsd",
  PRESTASHOP_CODE: "pts",
  BIGCOM_CODE: "bcm",
  PTS_PWA_CODE: "pts-pwa",
};

export const KeyFuncSupport = {
  productReview: "productReview",
};

export const HeaderKey = {
  JM360_VERSION: "JM360_VERSION",
  JM360_APP_VERSION: "JM360_APP_VERSION",
  JM360_APP_TYPE: "JM360_APP_TYPE",
  JM360_APP_ID: "JM360_APP_ID",
  JM360_APP_KEY: "JM360_APP_KEY",
  JM360_BUILD: "JM360_BUILD",
  JM360_OS_TYPE: "JM360_OS_TYPE",
  JM360_OS_VERSION: "JM360_OS_VERSION",
  JM360_DEVICE_MODEL: "JM360_DEVICE_MODEL",
  JM360_ENV: "JM360_ENV",
  APP_TYPE_CODE: "APP_TYPE_CODE",
  JM360_SO_KP: "jm360_so_kp",
  JM360_SO_KP_EXP: "jm360_so_kp_exp",
  JM360_SO_KP_VER: "jm360_so_kp_ver",
  JM360_KP_EXP: "jm360_kp_exp",
  JM360_KP: "jm360_kp",
  JM360_KP_VER: "jm360_kp_ver",
};

export const AnalyticEventName = {
  PRESS_MODULE_FROM_TAB_MORE: "press_module_from_tab_more",
  PRESS_SUB_CATEGORY: "press_sub_category",
  VIEW_PRODUCT_DETAIL: "view_item",
  VIEW_PRODUCT_LIST: "view_item_list",
  SEARCH: "search",
  ADD_TO_CART: "add_to_cart",
  UPDATE_TO_CART: "update_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  ADD_TO_WISHLIST: "add_to_wishlist",
  UPDATE_TO_WISHLIST: "update_to_wishlist",
  REMOVE_FROM_WISHLIST: "remove_from_wishlist",
  BEGIN_CHECK_OUT: "begin_checkout",
  ECOMMERCE_PURCHASE: "ecommerce_purchase",
};

export const KeyEmitter = {
  listenNotifi: "listenNotifi",
  userLoginSuccess: "userLoginSuccess",
};

export const TypeSearch = {
  byText: "0",
  byBarcode: "1",
};

export const AnalyticParamName = {
  actionName: "action_name",
  nameModule: "name_module",
  id: "id",
  name: "name",
  productId: "product_id",
  title: "title",
  searchTerm: "search_term",
  itemCategory: "item_category",
  itemId: "item_id",
  itemName: "item_name",
  quantity: "quantity",
  price: "price",
  currency: "currency",
  value: "value",
  transactionId: "transaction_id",
};

export const TypeRequest = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PUT: "PUT",
};

export const TypeBuild = {
  END_USER: "END_USER",
  PREVIEW_USER: "PREVIEW_USER",
};

export const Checkout = {
  Type: {
    MAGEJAM: 0,
    ONE_PAGE: 3,
    WEB_CHECKOUT: 99,
  },
};
export const MagentoCheckoutStep = {
  BILLING_STEP: 1,
  SHIPPING_STEP: 2,
  SHIPPING_METHOD_STEP: 3,
  PAYMENT_METHOD_STEP: 4,
  ORDER_REVIEW_STEP: 5,
};
export const CompleteCheckoutMode = {
  IN_APP_BROWSER: "0",
  DEFAULT_BROWSER: "1",
};
export const ShipTo = {
  SAME: "same",
  DIFFERENT: "different",
};
export const MagentoCheckoutMode = {
  BILLING: "billing",
  SHIPPING: "shipping",
};

export const keyNav = {
  userName: "userName",
  listLanguage: "listLanguage",
  appKey: "appKey",
  appTypeCode: "appTypeCode",
  objModule: "objModule",
  objBrowseList: "objBrowseList",
  sortBy: "sortBy",
  sortDirection: "sortDirection",
  typeList: "typeList",
  selectTypeListCb: "selectTypeListCb",
  selectSortCb: "selectSortCb",
  queryKey: "queryKey",
  typeSearch: "typeSearch",
  pressHeaderSearch: "pressHeaderSearch",
  productId: "productId",
  productType: "productType",
  listReview: "listReview",
  listConfigReview: "listConfigReview",
  fromSearchProduct: "fromSearchProduct",
  isAllowGuestReview: "isAllowGuestReview",
  listOptionProductDetail: "listOptionProductDetail",
  fromScreenToDetail: "fromScreenToDetail",
  loginSuccessCb: "loginSuccessCb",
  listLookBook: "listLookBook",
  indexSelectedLookBook: "indexSelectedLookBook",
  addressItem: "addressItem",
  userCurrentLocation: "userCurrentLocation",
  listOverView: "listOverView",
  reviewNum: "reviewNum",
  itemNotifi: "itemNotifi",
};

export const keyParams = {
  autoLogin: "autoLogin",
  signUpUrl: "signUpUrl",
  reloadProfile: "reloadProfile",
  // checkOut: "checkOut",
  handleLoginCb: "handleLoginCb",
  address: "address",
  editBillingSuccess: "editBillingSuccess",
  modeCheckOut: "modeCheckOut",
  cartData: "cartData",
  checkoutUrl: "checkoutUrl",
  paymentUrl: "paymentUrl",
  paymentResultUrl: "paymentResultUrl",
  orderIdThankYou: "orderIdThankYou",
  isFromCheckOut: "isFromCheckOut",
  reLoadAddressBook: "reLoadAddressBook",
  addressUrl: "addressUrl",
  noteWishList: "noteWishList",
  idItemWishList: "idItemWishList",
  onChangeDescription: "onChangeDescription",
  idItemOrderDetail: "idItemOrderDetail",
  productOrderDetails: "productOrderDetails",
  urlWebViewHome: "urlWebViewHome",
  paymentMethod: "paymentMethod",
  paramsApp: "paramsApp",
  totalPurchase: "totalPurchase",
};

export const TypeInputOption = {
  dropDown: "drop_down",
  area: "area",
  field: "field",
  multipleOption: "multiple",
  checkbox: "checkbox",
  radio: "radio",
  password: "password",
  selectDropDown: "select",
  multipleBundle: "multi",
};

export const KeyAsyncStore = {
  deviceKey: "deviceKey",
  appKey: "appKey",
  appTypeCode: "appTypeCode",
  userCart: "userCart",
  listKeySearchHistory: "listKeySearchHistory",
  listRecentView: "listRecentView",
  headerDevLogin: "headerDevLogin",
  baseUrl: "baseUrl",
  userNameDev: "userNameDev",
  userInfo: "userInfo", //user login save here.
  listAddress: "listAddress",
  globalSetting: "globalSetting",
  listModule: "listModule",
  listCacheProduct: "listCacheProduct",
  timeResetSetting: "timeResetSetting",
  listNotifi: "listNotifi",
  isShowShakeApp: "isShowShakeApp",
  keyPair: "keyPair",
  user: "user",
  permissions: "permissions",
  newAddresses: "newAddresses",
};

export const MAX_LENGTH_LIST_RECENT_VIEW = 10;
export const MAX_LENGTH_LIST_HISTORY = 5;

export const ModuleType = {
  MAIN_MENU: "MAIN_MENU",
  ECOMMERCE_CATALOG: "ECOMMERCE_CATALOG",
  USER_AUTH: "USER_AUTH",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  PRODUCT_CATALOG: "PRODUCT_CATALOG",
  PRODUCT_CATALOG_TEXT_BASE: "PRODUCT_CATALOG_TEXT_BASE",
  CUSTOMER_ENQUIRY: "CUSTOMER_ENQUIRY",
  ABOUT_US: "ABOUT_US",
  STORE_LOCATION: "STORE_LOCATIONS",
  HOME_SCREEN: "HOME_SCREEN",
  HOME_SCREEN2: "HOME_SCREEN2",
  PRODUCT_BARCODE_SCAN: "PRODUCT_BARCODE_SCAN",
  GA_TRACKERS: "GA_TRACKERS",
  MESSAGE_LIST: "MESSAGE_LIST",
  LOCATIONS: "LOCATIONS",
  BARBER_BOOKING: "BARBER_BOOKING",
  GLOBAL_SEARCH: "GLOBAL_SEARCH",
  LOOKBOOK: "LOOKBOOK",
  NONE: "NONE",
};

export const ModuleAction = {
  LOAD_MODULE: "LOADMODULE ",
  DIALOG: "DIALOG ",
  WEB: "WEB ",
  CALL: "CALL ",
  EMAIL: "EMAIL ",
  SMS: "SMS ",
  SCAN_BARCODE: "PRODUCT_BARCODE_SCAN",
  LOAD_PRODUCT: "LOAD_PRODUCT",
};

export const ProductType = {
  PRODUCT_TYPE_GROUP: "grouped",
  PRODUCT_TYPE_SIMPLE: "simple",
  PRODUCT_TYPE_CONFIGURABLE: "configurable",
  PRODUCT_TYPE_CONFIGURABLE_SCP: "configurable_scp",
  PRODUCT_TYPE_CONFIGURABLE_LP: "configurable_lp",
  PRODUCT_TYPE_BUNDLE: "bundle",
  PRODUCT_TYPE_PACK: "pack",
};

export const TypeSort = {
  AscSort: "asc",
  DescSort: "desc",
};

export const CurrencyKey = {
  KEY_USD: "USD",
  SYMBOL_USD: "$",
  KEY_AUD: "AUD",
  SYMBOL_AUD: "AU$",
  KEY_GBP: "GBP",
  SYMBOL_GBP: "UK£",
  KEY_EUR: "EUR",
  SYMBOL_EUR: "€",
  KEY_CNY: "CNY",
  SYMBOL_CNY: "CN¥",
  KEY_BDT: "BDT",
  SYMBOL_BDT: "Tk",
  KEY_SYP: "SYP",
  KEY_SEK: "SEK",
  SYMBOL_SEKL: "kr",
  KEY_SAR: "SAR",
  SYMBOL_SAR: "SR",
  KEY_XOF: "XOF",
  SYMBOL_XOF: "CFA",
};

export const alignInfo = {
  left: "left",
  right: "right",
  center: "center",
};

export const TypeItemList = {
  asLowAsType: "asLowAsType",
  startAtType: "startAtType",
  discountType: "discountType",
  fromToType: "fromToType",
  requireLoginType: "requireLoginType",
  basicPriceType: "basicPriceType",
  outOfStockType: "outOfStockType",
  basicAsLowAsType: "baseAsLowAsType",
  discountAsLowAsType: "discountAsLowAsType",
};

export const FROM_SCREEN_TO_DETAIL = {
  Cart: "Cart",
  WishList: "WishList",
  ProductList: "ProductList",
};

export const keyTimeStore = {
  Monday: "mon",
  Tuesday: "tue",
  Wednesday: "wed",
  Thursday: "thu",
  Friday: "fri",
  Saturday: "sat",
  Sunday: "sun",
};

export const paymentMethodCode = {
  iDEAL: "inapp-stripe-ideal",
  SOFORT: "inapp-stripe-sofort",
  Bancontact: "inapp-stripe-bancontact",
  Cards: "inapp-stripe-card",
  GooglePay: "inapp-stripe-google-pay",
  ApplePay: "inapp-stripe-apple-pay",
  Braintree: "in-app",
};

export const ErrMessage = {
  systemError: "System error",
  timeOutRequest: "Time out request",
  networkError: "Network error",
};

export const ApiKey = "afb65a68c2d09bc9f08a19a8b5a3d48ed2405737"