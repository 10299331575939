import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Result, Button} from "antd";
import {Redirect} from "react-router-dom";
import {CheckCircleOutlined, Loading3QuartersOutlined} from '@ant-design/icons';

import FormChangePassword from "../../components/FormChangePassword";
import * as Routes from "../../constants/route";
import "./changePassword.scss";

function mapStateToProps(state) {
  return {
    loggedIn: state.adminLogin.loggedIn,
    changePasswordSuccess: state.adminChangePassword.success
  }
}

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ForgotPasswordPage = ({loggedIn, changePasswordSuccess}: Props) => {
  return (
    changePasswordSuccess
      ? <Result
        status="success"
        icon={<CheckCircleOutlined/>}
        title=""
        subTitle="Your password has been updated"
        className={"update-password-success"}
        extra={[
          <Button type="primary" size={'large'} key={'btn'}>
            <Loading3QuartersOutlined spin/> Automatically logging in...
          </Button>
        ]}
      />
      : (loggedIn
        ? <FormChangePassword/>
        : <Redirect to={Routes.LOGIN_URL}/>
      )
  )
}

export default connector(ForgotPasswordPage);
