import {UserConstants} from "../../constants/user";

export function userGetByCompany(state = {users: []}, action) {
  switch (action.type) {
    case UserConstants.GET_BY_COMPANY_REQUEST:
      return {
        loading: true,
        companyId: action.companyId,
        users: state.users
      };
    case UserConstants.GET_BY_COMPANY_SUCCESS:
      return {
        users: action.users
      };
    case UserConstants.GET_BY_COMPANY_FAILURE:
      return {
        error: action.error,
        users: []
      };
    case UserConstants.CLEAR_ERRORS:
      return {
        users: state.users
      };
    case UserConstants.CLEAR_RESULTS:
      return {
        users: []
      };
    default:
      return state;
  }
}
