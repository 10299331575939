import React, {Component} from "react";
import {Form, Input, Button, Alert, Typography} from "antd";
import {CSSTransition} from "react-transition-group";
import {connect, ConnectedProps} from "react-redux";

import {AdminActions} from "../actions/admin";
import {AlertActions} from "../actions/alert";

const {Title} = Typography;

const passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/);

function mapStateToProps(state) {
  return {
    alert: state.alert,
    loading: state.adminChangePasswordForgot.requesting
  }
}

const mapDispatchToProps = {
  changePassword: AdminActions.changePasswordForgot,
  clearErrors: AlertActions.clear
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

class FormChangeForgotPassword extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(values) {
    this.props.clearErrors();
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    this.props.changePassword(searchParams.get('token'), values.password, values.confirm);
  }

  render() {
    const {alert, loading} = this.props;
    const alertShow = !!(alert && alert.message);

    return (
      <Form
        layout={'vertical'}
        hideRequiredMark={true}
        onFinish={this.onFormSubmit}
        name={'form-change-forgot-password'}
      >
        <Title level={3}>Change Password</Title>

        <CSSTransition
          in={alertShow}
          exit={false}
          timeout={300}
          unmountOnExit
          classNames={'pop-up'}
        >
          <Alert
            message={alert.message}
            type={alert.type}
            closable={true}
            showIcon={true}
          />
        </CSSTransition>

        <Form.Item
          label={'New password:'}
          name={'password'}
          rules={[
            {required: true, message: 'Please input new password!'},
            {
              pattern: passwordRegex,
              message: 'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'
            }
          ]}
        >
          <Input.Password disabled={loading}/>
        </Form.Item>

        <Form.Item
          label={'Confirm new password:'}
          name={'confirm'}
          dependencies={['password']}
          rules={[
            {required: true, message: 'Please confirm new password!'},
            ({getFieldValue}) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Confirm password does not match entered password!');
              }
            })
          ]}
        >
          <Input.Password disabled={loading}/>
        </Form.Item>

        <Form.Item className={'form-buttons form-buttons-solo'}>
          <Button
            className={'submit-btn'}
            type={'primary'}
            size={'large'}
            htmlType={'submit'}
            loading={loading}
          >{loading ? '' : 'Update'}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default connector(FormChangeForgotPassword);