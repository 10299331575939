import util from "util";

export const formatAddress = (address: any = {}) => {
  return !Object.keys(address).length ? '-' : util.format("%s, %s, %s, %s %s, %s",
    address.address1 || '',
    address.address2 || '',
    address.city || '',
    address.state || '',
    address.postalCode || '',
    address.country
  )
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;