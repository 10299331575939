import {CompanyConstants} from "../constants/company";
import {CompanyServices} from "../services/company";
import {AlertActions} from "./alert";
import {AddressConstants} from "../constants/address";

export const CompanyActions = {
  searchCompanies,
  deleteCompanies,
  saveCompany,
  clearErrors,
  clearResults,
  getCompaniesByUser,
  getCompanyById,
};

function searchCompanies(params = {}) {
  return dispatch => {
    dispatch(request(params));

    CompanyServices.searchCompanies(params)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(params) {
    return {type: CompanyConstants.SEARCH_REQUEST, params};
  }

  function success(result) {
    return {type: CompanyConstants.SEARCH_SUCCESS, result};
  }

  function failure(error) {
    return {type: CompanyConstants.SEARCH_FAILURE, error};
  }
}

function deleteCompanies(companyIds) {
  return dispatch => {
    dispatch(request(companyIds));

    CompanyServices.deleteCompanies(companyIds)
      .then(
        () => {
          dispatch(success(companyIds));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(companyIds) {
    return {type: CompanyConstants.DELETE_REQUEST, companyIds};
  }

  function success(companyIds) {
    return {type: CompanyConstants.DELETE_SUCCESS, companyIds};
  }

  function failure(error) {
    return {type: CompanyConstants.DELETE_FAILURE, error};
  }
}

function saveCompany(kpData, values) {
  return dispatch => {
    dispatch(request(values));

    CompanyServices.saveCompany(kpData, values)
      .then(
        () => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
          dispatch(AlertActions.error(error));
        });
  }

  function request(values) {
    return {type: CompanyConstants.SAVE_REQUEST, values}
  }

  function success() {
    return {type: CompanyConstants.SAVE_SUCCESS}
  }

  function failure(error) {
    return {type: CompanyConstants.SAVE_FAILURE, error}
  }
}

function clearErrors() {
  return {
    type: CompanyConstants.CLEAR_ERRORS
  }
}

function clearResults() {
  return {
    type: CompanyConstants.CLEAR_RESULTS
  }
}

function getCompaniesByUser(userId) {
  return dispatch => {
    dispatch(request(userId));

    CompanyServices.getCompaniesByUser(userId)
      .then(
        companies => {
          dispatch(success(companies));
        },
        error => {
          dispatch(failure(error));
        })
  }

  function request(userId) {
    return {type: CompanyConstants.GET_BY_USER_REQUEST, userId};
  }

  function success(companies) {
    return {type: CompanyConstants.GET_BY_USER_SUCCESS, companies};
  }

  function failure(error) {
    return {type: CompanyConstants.GET_BY_USER_FAILURE, error};
  }
}

function getCompanyById(companyId) {
  return dispatch => {
    dispatch(request(companyId));

    CompanyServices.getCompanyById(companyId)
      .then(
        company => {
          dispatch(success(company));

          if (company.shippingAddresses && company.shippingAddresses.length) {
            dispatch(setAddresses(company.shippingAddresses));
          }
        },
        error => {
          dispatch(failure(error));
        });
  }

  function request(userId) {
    return {type: CompanyConstants.GET_BY_ID_REQUEST, userId};
  }

  function success(company) {
    return {type: CompanyConstants.GET_BY_ID_SUCCESS, company};
  }

  function failure(error) {
    return {type: CompanyConstants.GET_BY_ID_FAILURE, error};
  }

  function setAddresses(addresses) {
    return {type: AddressConstants.SET_ADDRESS, addresses};
  }
}