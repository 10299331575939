export const baseUrl = 'https://b2b-user.gill.jmango360.com';
export const DEV_REGISTER_SERVER = "http://18.139.253.166:8091/endpoints";
export const PRO_REGISTER_SERVER = "https://register.jmango360.com/endpoints";



export const loginUser = '/b2b-user/managers/login';
export const changePassword = '/b2b-user/managers/change/password';
export const requestEmailForgotPassword = '/b2b-user/managers/forgot/password';
export const changeForgotPassword = '/b2b-user/managers/change/forgot/password';

export const UserUrl = 'http://18.139.253.166:8091';

export const getRegisterUserFieldForm = '/b2b-user/users/admin/signup-form?appKey=%s';
export const getEditUserFieldForm = '/b2b-user/users/admin/signup-form?appKey=%s&userId=%s';
export const getRoles = '/b2b-user/roles/get';
export const registerUser = '/b2b-user/users/admin/register';
export const deleteUsers = '/b2b-user/users/delete';
export const getUserById = '/b2b-user/users/%s';
export const adminEditUser = '/b2b-user/users/admin/edit';
export const getListUser = '/b2b-user/users?pageNumber=%s&pageSize=%s';
export const searchUser = '/b2b-user/users/search?pageNumber=%s&pageSize=%s&email=%s&name=%s&roleId=%s';
export const registerCompany = '/b2b-user/companies/create';
export const getListCompany = '/b2b-user/companies?appKey=%s&pageNumber=%s&pageSize=%s';
export const searchCompany = '/b2b-user/companies/search?appKey=%s&pageNumber=%s&pageSize=%s&name=%s&owner=%s&phone=%s&address=%s';
export const getCompanyById = '/b2b-user/companies/%s';
export const deleteCompanies = '/b2b-user/companies/delete';
export const editCompany = '/b2b-user/companies/edit';
export const getCountry = '/b2b-user/companies/countries?appKey=%s';
export const getState = '/b2b-user/companies/countries/%s/states?appKey=%s';
export const createAddress = '/b2b-user/companies/addresses/create';
export const deleteAddress = '/b2b-user/companies/addresses/%s/delete';
export const editAddress = '/b2b-user/companies/addresses/edit';
export const getCompaniesAssignedToAUser = '/b2b-user/users/%s/companies?pageNumber=%s&pageSize=%s';
export const editUser = '/b2b-user/users/admin/edit';
export const assignCompanyToUser = '/b2b-user/users/companies/assign';
export const editAccountAdmin = '/b2b-user/managers/edit';
export const getAdminProfile = '/b2b-user/managers/current';
export const getCustomerGroup = '/b2b-user/users/groups?appKey=%s';

export const amazonUrl =
    "http://ec2-18-139-253-166.ap-southeast-1.compute.amazonaws.com:3023";
export const getListApp = "/common/apps/";
export const getListLanguage = "/common/apps/getAssociated/%s";
export const googleLogin = "/common/google-plus/so-login";
export const facebookLogin = "/common/facebook/so-login";
export const registerApp = "/common/%s/register";
export const syncApp = "/common/%s/sync";
export const getProductsModule = "/ecom/%s/products";
export const getListCart =
    "ecom/%s/carts/cart?appKey=%s&deviceKey=%s&ticket=%s";
export const deleteCartItem = "ecom/%s/carts/cart/%s/items/item/%s";
export const editquanityCartItem = "ecom/%s/carts/cart/%s/items/item/%s/qty";
export const applyCouponCart = "ecom/%s/carts/cart/%s/coupon";
export const removeCouponCart = "ecom/%s/carts/cart/%s/coupon/%s";
export const getCrossSell =
    "ecom/%s/products/%s/cross_sell?appKey=%s&deviceKey=%s&ticket=%s";
export const getCartCount =
    "ecom/%s/carts/cart/count?appKey=%s&deviceKey=%s&ticket=%s";
export const addItemToNewCart = "/ecom/%s/carts/cart";
export const addItemIntoExistingCart = "ecom/%s/carts/cart/%s/items";
export const updateItemIntoExistingCart =
    "/ecom/%s/carts/cart/%s/items/item/%s";
export const loginMagento = "ecom/%s/accounts/login";
export const forgetPass = "ecom/%s/accounts/forgot-password";
export const registerMagento = "ecom/%s/accounts/register-extra";
export const createUserAddress = "ecom/%s/accounts/%s/addresses";
export const getUserAddress =
    "ecom/%s/accounts/%s/addresses?deviceKey=%s&appKey=%s";
export const editUserAddress = "ecom/%s/accounts/%s/addresses";
export const setCartAddress = "ecom/%s/carts/cart/%s/addresses";
export const setCartShippingMethod = "ecom/%s/carts/cart/%s/shipping";
export const getTokenPayment = "ecom/%s/carts/payments/in-app/tokens";
export const updatePaymentComplete = "ecom/%s/carts/cart/%s/complete";
export const autoLogin =
    "/ecom/%s/autologin/%s/%s/?url=%s&keypairData=%s&keypairVersion=%s";
export const getRegionList = "ecom/%s/region/list";
export const onePageCheckOut = "/ecom/%s/payment/webcheckout/%s/%s";
export const userLogOut = "ecom/%s/accounts/logout";
export const editProfilt = "ecom/%s/accounts/update";
export const reloadProfile = "ecom/%s/accounts/%s/?appKey=%s&deviceKey=%s";
export const deleteUserAddress =
    "ecom/%s/accounts/%s/addresses?appKey=%s&deviceKey=%s&ticket=%s&customerId=%s&addressId=%s";
export const getWishList = "ecom/%s/accounts/wishlist/get";
export const updateWishList = "ecom/%s/accounts/wishlist/update";
export const updateProductInWatchList =
    "/ecom/%s/accounts/wishlist/updateoptions";
export const removeWishList = "ecom/%s/accounts/wishlist/remove";
export const getOrderList =
    "ecom/%s/accounts/%s/orders?appKey=%s&deviceKey=%s&pageSize=%s&pageNumber=%s";
export const getOrderDetails =
    "ecom/%s/accounts/%s/orders/%s/?appKey=%s&deviceKey=%s";
export const sendEnquiry = "ecom/%s/enquiry/submit";
export const associateSourceStripe = "ecom/%s/payment/stripe/sources/associate";
export const submitChargesStripe = "ecom/%s/payment/stripe/charges/submit";
export const initPaymentIntent =
    "ecom/%s/payment/stripe/auto/payment-intent/init";
export const completeOrderStripe = "ecom/%s/carts/completeOrder";

export const searchSuggestion = "ecom/%s/searchsuggestion";
export const listProductSearch = "ecom/%s/products/search";
export const validatePayment = "ecom/%s/carts/validatePayment";
// API Detail product.
export const detailProduct = "/ecom/%s/products/details";
export const reviewDetailProduct =
    "/ecom/%s/products/%s/reviews?appKey=%s&deviceKey=%s";
export const configReviewDetailProduct =
    "/ecom/%s/products/%s/reviews/config?appKey=%s&deviceKey=%s";
export const relatedProduct =
    "/ecom/%s/products/%s/related?appKey=%s&deviceKey=%s&ticket=%s";
export const upSellProduct =
    "/ecom/%s/products/%s/up_sell?appKey=%s&deviceKey=%s&ticket=%s";
export const addReviewProduct = "/ecom/%s/products/%s/reviews";
export const addProductToWatchList = "/ecom/%s/accounts/wishlist/add";
export const removeProductFromWatchList = "/ecom/%s/accounts/wishlist/remove";
// get price sale of config_scp product after finish picking all option
export const getPriceSaleProduct = "/ecom/%s/products/%s/reload";

//API Push message
export const registerPushNotifi = "/common/%s/register-push";
export const integrateUserUrl = "https://bigcommerce-batch-web.prodeu.jmango360.com";