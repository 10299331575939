import React, {useEffect, useRef} from "react";
import {Route, Redirect} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {parseISO, compareAsc} from "date-fns";

import AdminLayout from "../layouts/AdminLayout";
import * as Routes from "../constants/route";
import {AdminActions} from "../actions/admin";
import {AlertActions} from "../actions/alert";
import {UserActions} from "../actions/user";
import {CompanyActions} from "../actions/company";
import {AddressActions} from "../actions/address";
import {ADMIN_TIMEOUT} from "../constants";

const CompanyEditRouteRegex = /company\/edit/;
const CompanyManageRouteRegex = /company\/manage/;
const CompanyViewRouteRegex = /company\/view/;
const UserEditRouteRegex = /user\/edit/;
const UserCreateRouteRegex = /user\/create/;
const UserManageRouteRegex = /user\/manage/;
const UserViewRouteRegex = /user\/view/;

function mapStateToProps(state) {
  return {
    loggedIn: state.adminLogin.loggedIn,
    admin: state.adminLogin.admin,
    expirationTime: state.adminLogin.expirationTime,
    router: state.router
  }
}

const mapDispatchToProps = {
  updateExpirationTime: AdminActions.updateExpirationTime,
  expireSession: AdminActions.expireSession,
  clearAlert: AlertActions.clear,
  clearAdmin: AdminActions.clear,
  clearUserResults: UserActions.clearResults,
  clearListAgentResults: UserActions.clearListAgent,
  clearCompanyResults: CompanyActions.clearResults,
  clearAddressResults: AddressActions.clearResults,
    clearListAgent: UserActions.clearListAgent,
    clearResultAddress: AddressActions.clearResultAddressLocal
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  component,
  path
};

const AdminRoute = ({component: Component, loggedIn, admin, ...rest}: Props) => {
  const previousPathname = useRef('');

  useEffect(() => {
    const {router: {location: {pathname}}} = rest;

    rest.clearAlert();
    rest.clearAdmin();
    rest.clearAddressResults();
    rest.clearResultAddress();
    rest.clearListAgent();


    if (!pathname.match(CompanyViewRouteRegex)
      && !pathname.match(CompanyEditRouteRegex)
      && !pathname.match(CompanyManageRouteRegex)
      || previousPathname.current.match(UserCreateRouteRegex)
    ) {
      rest.clearCompanyResults();
    }

    if (!pathname.match(UserViewRouteRegex)
      // && !pathname.match(UserEditRouteRegex) // comment for ticket GM-1525
      && !pathname.match(UserManageRouteRegex)
    ) {
      rest.clearUserResults();
    }

      if (!pathname.match(UserEditRouteRegex)) {
          rest.clearListAgentResults();
      }

    previousPathname.current = pathname;

    checkExpirationTime();
  }, [rest.router]);

  const timer = useRef<NodeJS.Timeout>();

  const checkExpirationTime = () => {
    const expirationTime = parseISO(rest.expirationTime);
    if (compareAsc(new Date(), expirationTime) === 1) {
      logout();
    } else {
      rest.updateExpirationTime();

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(logout, ADMIN_TIMEOUT * 1000);
    }
  }

  const logout = () => {
    rest.expireSession();
  }

  return (
    <Route {...rest} render={props => (
      loggedIn
        ? (admin.resetPassword === 0
          ? <Redirect to={Routes.CHANGE_PASSWORD_URL}/>
          : <AdminLayout location={props.location}><Component {...props}/></AdminLayout>
        )
        : <Redirect to={Routes.LOGIN_URL}/>
    )}/>
  )
};

export default connector(AdminRoute);