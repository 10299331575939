import {CompanyConstants} from "../../constants/company";

export function companyByUser(state = {companies: []}, action) {
  switch (action.type) {
    case CompanyConstants.GET_BY_USER_REQUEST:
      return {
        loading: true,
        userId: action.userId,
        companies: state.companies
      }
    case CompanyConstants.GET_BY_USER_SUCCESS:
      return {
        companies: action.companies
      }
    case CompanyConstants.GET_BY_USER_FAILURE:
      return {
        error: action.error,
        companies: []
      }
    case CompanyConstants.CLEAR_ERRORS:
      return {
        companies: state.companies
      }
    case CompanyConstants.CLEAR_RESULTS:
      return {
        companies: []
      }
    default:
      return state;
  }
}
